import React, { useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import Text from '../../../components/Text';
import Title from "../../../components/Title";
import ceoImg from '../../../assets/images/dynamic/ceo.jpg';
import ceoImgMobile from '../../../assets/images/dynamic/ceo-mobile.jpg';
import {baseUrl} from "../../../components/BaseUrl";
import ReactHtmlParser from "react-html-parser";
import Modal from '../../../components/Modal'
import Button from "../../../components/Button";
import arrowImg from "../../../assets/images/btn-arrow-grey.svg";

const CeoMessage = ({ posts }) => {

  let [openModal, setOpenModal] = useState(false)

  let ceoPost = posts ? posts.find(post => post.data.slug === 'chairman-message') : null
  let ceoData = ceoPost ? ceoPost.data ? ceoPost.data : null : null

  let ceoImageList = ceoPost ? ceoPost.images ? ceoPost.images : [] : []

  let mobileObj = ceoImageList ? ceoImageList.find(image => image.short_title === 'chairman') : null
  let deskObj = ceoImageList ? ceoImageList.find(image => image.short_title === 'chairman') : null

  let mblImage = mobileObj ? baseUrl + mobileObj.path + mobileObj.img_name : ''
  let deskImage = deskObj ? baseUrl + deskObj.path + deskObj.img_name : ''

  let handleModal = () => {
    setOpenModal(!openModal)
  }


  return (
    <StyledCeoMessage className="ceo-message">
      <Container>
        <Modal
          show={openModal}
          handleClose={handleModal}
          post={ceoData ? ceoData.description : ''}
          title={ceoData ? ceoData.title : ''}
        />
        <Row>
          <Col xs={{ span: 5 }}>
            <img src={mblImage} alt=""/>
          </Col>
          <Col xs={{span: 7}}>
            <div className="ceo-message__text">
              <SubTitle
                text={'chairman message'}
                margin={'0 0 20px 0'}
                color="#008C44"
                lineHeight={46}
                fontSize={14}
              />
              <Title
                text={ceoData ? ceoData.title : ''}
                margin={'0 0 20px 0'}
                color="#474747"
                fontSize={36}
              />
              <Text color='#474747' margin={'0 0 20px 0'} fontSize={16} line={6}>
                <img src={deskImage} alt=""/>
                <p>
                  {ceoData ? ReactHtmlParser(ceoData.description) : ''}
                </p>
              </Text>
              <div onClick={() => handleModal()}>
                <Button
                  link={'/about'}
                  margin={'40px 0 0 0'}
                  text={'Read More'}
                  color={'#304550'}
                  img={arrowImg}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </StyledCeoMessage>

  )
};

const StyledCeoMessage = styled.div`
    background-color: #E2E2E2;
    background-position: left;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;

  .container {
    margin-left: 0;
    padding-left: 0;
  }
  .col-5 {
    img{
      display: block;
      width:100%;
      object-fit: contain;
    } 
  }
  
  .col-7 {
    padding: 50px 30px;
    img {
      display: none;
    }
  }

    @media(max-width:800px){
    .pt-110, .pt-150 {
      padding-right: 48px;
    }
      .col-7{
        min-width: 100%;
        margin-left: 0;
        img{
          display: block;
          max-width:100%;
          object-fit: contain;
          margin-bottom: 30px;
        }
      }
      p{
        font-size: 16px;
        font-weight: 300;
      }
      .col-5 {
        img {
          display: none;
        }
      }
    }
  @media(max-width:767px){
    .pt-110, .pt-150 {
      padding-right: 15px;
    }
    .col-7 {
      padding: 30px;
    }
  }
`;


export default CeoMessage;
