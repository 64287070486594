import React from 'react';
import styled from 'styled-components';
import InnerBanner from "../../../components/InnerBanner";

import {connect} from "react-redux";
import ReactHtmlParser from "react-html-parser";
import {Container, Row, Col} from 'react-bootstrap';
import Title from "../../../components/Title";
import SubTitle from "../../../components/SubTitle";

const Description = ({footerOffset, aboutImg, postData, parentTitle, title, date}) => {
  return (
    <StyledDescription offset={footerOffset}>
      <div className="description-content">
        <InnerBanner
          title={parentTitle}
          image={aboutImg}
        />
        <div className="description__wrap">
          <Container>
            <SubTitle
                text={date}
                margin={'0 0 20px 0'}
                fontSize={16}
            />
            <Title
                text={title}
                color={'#304550'}
                margin={'0 0 30px 0'}
                fontSize={28}
            />
            <div className="description__wrap__about">
              {ReactHtmlParser(postData ? postData.description : '')}
            </div>
          </Container>

        </div>

      </div>

    </StyledDescription>
  )
};

const StyledDescription = styled.div`
  .description-content{
    background-color: #fff;
    .description__img{
      height: 600px;
      position: relative;
      img{
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }
  }
  .description__wrap{
    background: #FFF;
    // width: calc(100% - ${props=> props.offset +15}px);
    // margin-left: ${props=> props.offset +15 || 200}px;
    // padding: 100px;
    position: relative;
    z-index: 2;
    &__about{
      width: 80%;
    }
    img {
      width: 100%;
      object-fit: contain;
      position: relative;
      height: auto;
      margin-bottom: 40px;
      margin-top: 20px;
    }
    p{
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 20px;
      color: #304550;
      &:nth-last-of-type(1){
        margin-bottom: 0;
      }
    }
  }

  @media(max-width:1080px){
    .container{
      overflow-x: hidden;
    }
    overflow-x: hidden;
  }
  @media(max-width:1080px){
    .description__wrap{
      overflow: hidden;
      //width: calc(100% - 15px);
      //margin-left: 15px;
    }
  }

  @media(max-width:992px){
    .description__wrap{
      padding: 80px 35px;
    }
    .description__img {
      height: 500px;
      position: relative;
    }
  }


  @media(max-width:767px){

    .description__wrap{
      padding: 80px 20px;
      img{
        margin-top: 10px;
        margin-bottom: 30px;
      }
    }
    .description__img {
      height: 500px;
      position: relative;
    }
    p{
      font-size: 16px;
      line-height: 24px;
    }
    .description__wrap__at-a-glance{
      margin-top: 60px;
      padding-top: 60px;
      table tr td{
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 16px !important;
        line-height: 21px !important;
      }
    }

  }

`;

let mapStateToProps = state => {
  return {
    footerOffset: state.footerReducer.footerOffset
  }
};

export default connect(mapStateToProps) (Description);
