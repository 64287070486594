import React from 'react';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';
const MiniBar = ({text, fontSize, fontWeight, color, letterSpacing, lineHeight, textTransform, margin}) => {
  return (

    <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
      {({isVisible}) =>
        <StyledTitle  className={`${isVisible ? 'anim-active' : ''} fade-up title`}
                      fontSize={fontSize}
                      fontWeight={fontWeight}
                      color={color}
                      lineHeight={lineHeight}
                      letterSpacing={letterSpacing}
                      textTransform={textTransform}
                      margin={margin}>{text}
        </StyledTitle>
      }
    </VisibilitySensor>
  )
};


const StyledTitle = styled.span`
  display: block;
  width: 80px;
  height: 5px;
  position: relative;
  font-size: ${props => props.fontSize || 10}px;
  font-weight:${props => props.fontWeight || 'bold'};
  margin: ${props => props.margin || '0px'};;
  line-height:${props => props.lineHeight || 28}px;
  color:${props => props.color || '#000000'};
  text-transform: ${props => props.textTransform || 'capitalize'} ;

  &:after{
    content: '';
    position: absolute;
    background-color: #21FF8D;
    height: 100%;
    width: 100%;
  }

  @media(max-width:767px){
    font-size: 5px;
    line-height: 32px;
    margin-bottom: 30px;
  }
`;


export default MiniBar;
