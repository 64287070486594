import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import SubTitle from './SubTitle';


import {ApiParams} from "../constants/apiParams";
import {connect} from 'react-redux';
import {fetchHomeAboutUsData} from "../redux/actions/home";

class NumberRow extends Component {
  componentDidMount() {
    let params = {
      [ApiParams.page_type]: 'home-about'
    };
    this.props.getHomeAboutUsData(params)
  }

  render() {
    let {aboutData} = this.props;
    const aboutPost = aboutData ?
      aboutData.posts ?
        aboutData.posts.list ?
          aboutData.posts.list.filter(item => {
            if(item.data["Extra description one"] === "number") return item
          }) : []
        : []
      : []
    
    return (
      <StyledAbout className="pt-110 pb-110">
        <Container>
          <Row>
            <Col sm={12} className="about__right">
              {aboutPost ? aboutPost.map((data) => (
                <div key={data.data.id} className="about__right__single">
                  <SubTitle
                    margin={'0 0 30px 0'}
                    text={'over'}
                  />
                  <h4>
                    <CountUp start={0} end={parseInt(data.data.title)}>
                      {({countUpRef, start}) => (
                        <VisibilitySensor
                          onChange={start}
                          partialVisibility={{top: 0, bottom: 20}}
                          delayedCall
                        >
                          <span ref={countUpRef}></span>
                        </VisibilitySensor>
                      )}
                    </CountUp>+</h4>
                  <div dangerouslySetInnerHTML={{__html: data.data.description}}/>
                </div>
              )) : null}
            </Col>

          </Row>
        </Container>
      </StyledAbout>
    )
  }
}


const StyledAbout = styled.div`
  background-color: #fff;
  position: relative;
  .container{
    position: relative;
  }


  .about__left{
    @media(max-width:767px){
      min-width: 100%;
      .title{
        margin-bottom: 17px !important;
      }
    }
  }
  .about__right{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 20px 0px;
    border-top: 0.5px solid rgba(48, 69, 80, 0.4);
    border-bottom: 0.5px solid rgba(48, 69, 80, 0.4);
    &__single{
      margin-bottom: 8px;
      h4{
        font-size: 50px;
        line-height: 58px;
        font-weight: 300;
        margin: 0;
      }
      p{
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
        margin: 0;
        text-transform: uppercase;
        span{
          font-weight: bold;
        }
      }
    }
    @media(max-width:767px){
      flex-direction: column;
      min-width: 100%;
      margin-top: 60px;
      margin-left: 0;
      padding: 20px 15px;
      .about__right__single{
          text-align: left;
          margin-bottom: 40px;
          &:nth-last-of-type(1){
            margin-bottom: 0;
          }
          &:nth-of-type(2){
            text-align: right;
          }
          h4{
            font-size: 32px;
            line-height: 40px;
          }
          p{
            font-weight: 400;
            br{
              display: none;
            }
          }
      }
    }
  }
`;

function mapStateToProps(state) {
  return {
    aboutData: state.homeReducer.aboutData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getHomeAboutUsData: (params) => dispatch(fetchHomeAboutUsData(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NumberRow);




