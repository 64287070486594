import React, {useLayoutEffect, useEffect} from 'react';
import InnerTextBanner from '../../components/InnerTextBanner';
import DivisitonAbout from './components/About';
import MachineSlider from './components/MachineSlider';
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import Resources from "./components/Resources";
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {fetchDivisionListChildPage} from '../../redux/actions/division'
import {fetchFormData, postFormSubmit} from "../../redux/actions/form";
import Menu from '../../components/Menu';
import logoBlack from '../../assets/images/logo-black.svg';
import hamburderBlack from '../../assets/images/hamburger-black.svg';
import {ApiParams} from "../../constants/apiParams";
import ClientSlider from './components/OurClient';
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {Loading} from "../../components/Loading";
import {Helmet} from "react-helmet";


const DivisionDetail = (props) => {
    let {slug} = props.match.params;

    useEffect(() => {
        let params = {
            [ApiParams.page_slug]: slug
        }
        props.getDivisionListChildPage(params)

        let formParams = {
            [ApiParams.form_slug]: 'contact-form'
        }
        props.getFormData(formParams)
    }, [slug]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let {divListChildPage, formFields, postFormData} = props

    let title = divListChildPage ? divListChildPage.page_data ? divListChildPage.page_data.title : '' : '';
    let subTitle = divListChildPage ? divListChildPage.page_data ? divListChildPage.page_data.sub_title : '' : '';
    let link = divListChildPage ? divListChildPage.page_data ? divListChildPage.page_data.short_desc : '' : '';

    let description = divListChildPage ?
      divListChildPage.page_data ? divListChildPage.page_data.description : ''
      :''

    let posts = divListChildPage ?
        divListChildPage.posts ?
            divListChildPage.posts.count ? divListChildPage.posts.list : []
            : [] : [];

    return (
        <>
            <Helmet>
                <title>{title ? title : slug}</title>
            </Helmet>
            <div>
                <Menu />
                {props.divChildPageLoading && <Loading/>}
                {divListChildPage ? (
                    <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                        <DivisitonAbout
                            slug={slug}
                            title={title}
                            posts={posts}
                            description={description}
                        />
                        <Gallery posts={posts}/>
                        <Contact
                            title={title}
                            posts={posts}
                        />
                    </motion.div>
                ) : ''}
            </div>
        </>
    )
};

let mapStateToProps = state => {
    return {
        divListChildPage: state.divisionReducer.divListChildPage,
        divChildPageLoading: state.divisionReducer.divChildPageLoading
    }
}

let mapDispatchToProps = dispatch => {
    return {
        getDivisionListChildPage: params => dispatch(fetchDivisionListChildPage(params)),
        getFormData: params => dispatch(fetchFormData(params))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DivisionDetail));
