import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import Lines from '../../../components/VerticalLine';
import {Link} from "react-router-dom";
import MiniBar from "../../../components/MiniBar";

import {ApiParams} from "../../../constants/apiParams";
import {connect} from 'react-redux';
import {fetchHomeAboutUsData} from "../../../redux/actions/home";
import arrowImg from "../../../assets/images/btn-arrow-grey.svg";
import arrowImgHover from '../../../assets/images/btn-arrow-green.svg'
import lifeAt from "../../../assets/images/lifeat.jpg";
import ReactHtmlParser from "react-html-parser";
import {baseUrl} from "../../../components/BaseUrl";

let LifeAt = props => {
  let { aboutData } = props

  let posts = aboutData ?
    aboutData.posts ?
      aboutData.posts.list ?
        aboutData.posts.list.filter(item => item.data.slug === 'career' || item.data.slug === 'gallery') : []
      : []
    : []

  return (
    <StyledServices>
      <Container fluid className='p-0'>
        {posts ? posts.map(post => {
          let postData = post.data
          let imagePath = post.images ?
            post.images[0] ? baseUrl + post.images[0].path + post.images[0].img_name : ''
            : ''
          return (
            <Row className="services-single">
              <Col md={7}>
                <div className="services-single__right">
                  <MiniBar margin={'0 0 30px 0'}/>
                  <Title
                    text={postData ? postData.title : ''}
                    fontSize={36}
                    lineHeight={55}
                    margin={'20px 0'}
                    color={'#304550'}
                  />
                  <Text color={'#304550'}>
                    <p>{ReactHtmlParser(postData ? postData.description : '')}</p>
                  </Text>
                  <Button
                    link={`/${postData.slug}`}
                    margin={'40px 0 40px 0'}
                    text={'explore more'}
                    color={'#304550'}
                    img={arrowImg}
                    borderColor={`#304550`}
                  />
                </div>
              </Col>
              <Col md={5}>
                <div className="services-single__left">
                  <img src={imagePath} alt="img" />
                </div>
              </Col>
            </Row>
          )
        }) : ''}
      </Container>
    </StyledServices>
  )
}


const StyledServices = styled.div`
    .services-single {
      margin-bottom: 60px;
     &:nth-of-type(even){
       flex-direction: row-reverse;
     } 
      .col-md-7{
        padding: 0;
      }
      .services-single__left{
        padding-top: 67.85%;
        position: relative;
       
        img{
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          object-fit: cover;
        }
      }
      .services-single__right{
        padding: 100px 85px 50px 85px;
        height: 100%;
      }
    }
    @media(max-width:991px){
      .services-single__right{
        padding-top: 10px !important;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .sub-title{
          margin-bottom: 20px;
        }
      }
      .col-md-7 {
         &:nth-of-type(even) .services-single__right{
          padding-left: 30px !important;
        }
         &:nth-of-type(odd) .services-single__right{
          padding-right: 30px !important;
        }
      }
   
    }
    @media(max-width:767px){
      .services-single{
      padding-bottom: 50px;
       .col-md-7:nth-last-of-type(1){
        padding:0 15px;
      }
      &:nth-of-type(even){
        display: flex;
        //flex-direction: column-reverse;
      }
         .col-md-7 {
           padding: 13px;
           .services-single__right{
             padding-left: 15px !important;
             padding-right: 15px !important;

             .sub-title{
               margin-top:10px;
               margin-bottom: 10px;
             }
           }
         } 
      }
    }
`;

function mapStateToProps(state) {
  return {
    aboutData: state.homeReducer.aboutData
  };
}

export default connect(mapStateToProps)(LifeAt);




