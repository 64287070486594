import React, {useState} from 'react';
import {Container, Row, Col, Form} from 'react-bootstrap';
import Title from '../../../components/Title';
import ButtonSubmit from '../../../components/ButtonSubmit';
import Message from '../../../components/PopupMessage';
import Button from "../../../components/Button";
import MapButton from "../../../components/MapButton";
// img
import rightArrow from '../../../assets/images/btn-arrow-black.svg';

import {connect} from 'react-redux'
import {postFormSubmit} from "../../../redux/actions/form";
import Text from "../../../components/Text";
import arrowImg from "../../../assets/images/btn-arrow-green.svg";
import ReactHtmlParser from "react-html-parser";

let ContactForm = ({ description, link }) => {

  return (
    <Col sm={6} className="map-right pt-110 pb-110">
      <Title
        text={'contact us'}
        margin={'0 0 50px 0'}
        fontSize={42}
        color={'#304550'}
      />
      <Text
        margin={'0 0 20px 0'}
        fontWeight={'400'}
        lineHeight={'27'}
        fontSize={'18'}
      >
        {ReactHtmlParser(description)}
      </Text>
      <MapButton
        link={link}
        margin={'40px 0 0 0'}
        text={'Google Maps'}
        img={arrowImg}
      />
    </Col>
  )
};

export default ContactForm
