import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import Lines from '../../../components/VerticalLine';
import {Link} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import {ApiParams} from "../../../constants/apiParams";
import {connect} from 'react-redux';
import {fetchHomeAboutUsData} from "../../../redux/actions/home";
import arrowImg from "../../../assets/images/btn-arrow-new.svg";

class AboutUs extends Component {
  componentDidMount() {
    let params = {
      [ApiParams.page_type]: 'home-about'
    };
    this.props.getHomeAboutUsData(params)
  }

    render() {
        let {aboutData} = this.props;
        const aboutPageData = aboutData ? aboutData.page_data : null;

        return (
            <StyledAbout className="pt-110 pb-110">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <SubTitle
                              margin={'0 0 40px 0'}
                              text={'About us'}
                            />
                        </Col>

                        <Col sm={6} className="about__left">
                            {aboutPageData ?
                                <>
                                    <Title
                                      fontSize={'20'}
                                      lineHeight={'30'}
                                      margin={'0 0 40px 0'}
                                      fontWeight={'500'}
                                      text={aboutPageData ? ReactHtmlParser(aboutPageData.short_desc) : ''}
                                    />
                                </> : ''
                            }
                        </Col>

                        <Col sm={6} className="about__right">
                            <Row>
                                <Col sm={12}>
                                    <Text
                                      fontSize={14}
                                      lineHeight={24}
                                      text={aboutPageData ? ReactHtmlParser(aboutPageData.description) : ''}
                                    />
                                </Col>
                                <Col sm={12}>
                                    <Button
                                      link={'/about'}
                                      margin={'40px 0 0 0'}
                                      text={'explore'}
                                      color={'#304550'}
                                      img={arrowImg}
                                      borderColor={`#304550`}
                                    />
                                </Col>
                            </Row>

                        </Col>

                    </Row>
                </Container>
            </StyledAbout>
        )
    }

}


const StyledAbout = styled.div`
  //background-color: rgba(30,195,170,0.05);
  position: relative;
  .container{
    position: relative;
  }


  .about__left{
    @media(max-width:767px){
      min-width: 100%;
      .title{
        margin-bottom: 17px !important;
      }
    }
  }
  .about__right{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    &__single{
      margin-bottom: 8px;
      h4{
        font-size: 110px;
        line-height: 147px;
        font-weight: 300;
        margin: 0;
      }
      p{
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
        margin: 0;
        text-transform: uppercase;
        span{
          font-weight: bold;
        }
      }
    }
    a{
      &:hover{
        span{
          img{
            filter: invert(0);
          }
        }
      }
    }

    .outer-button{
      img{
        stroke: #000;
        fill: #000;
        filter: invert(1);
      }
    }
    @media(max-width:767px){
      flex-direction: column;
      min-width: 100%;
      margin-top: 60px;
      margin-left: 0;
      .about__right__single{
        text-align: left;
        margin-bottom: 40px;
        &:nth-last-of-type(1){
          margin-bottom: 0;
        }
        &:nth-of-type(2){
          text-align: right;
        }
        h4{
          font-size: 50px;
          line-height: 80px;
        }
        p{
          font-weight: 400;
          br{
            display: none;
          }
        }
      }

    }
  }
`;

function mapStateToProps(state) {
  return {
    aboutData: state.homeReducer.aboutData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getHomeAboutUsData: (params) => dispatch(fetchHomeAboutUsData(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);




