import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import {hover} from "../../../globalStyleVars";
import ReactHtmlParser from "react-html-parser";
import Lines from '../../../components/VerticalLine';

// css
import "swiper/swiper.scss";

// img
import RightIcon from "../../../assets/images/navRightBlack.svg";
import LeftIcon from "../../../assets/images/navLeftBlack.svg";

import {baseUrl} from "../../../components/BaseUrl";
import $ from "jquery";

const History = ({historyMain}) => {
    let historyData = historyMain ? historyMain.page_data ? historyMain.page_data : null : null
    let historyTitle = historyData ? historyData.sub_title : ''

    let historyPostData = historyMain ?
        historyMain.posts ? historyMain.posts.list : []
        : []

    let len = historyPostData.length

    const params = {
        slidesPerView: 1,
        spaceBetween: 30,
        speed: 1500,
        freeMode: false,
        breakpoints: {
            769: {
                slidesPerView: 3,
                spaceBetween: 30,
                freeMode: true,

            },
        }
    };


    useEffect(() => {
        let getOffset = $('.container').offset().left + 15;
        if ($(window).width() > 768) {
            $('.history-slider .swiper-container').css({'padding-left': getOffset + 170});
        }
    });


    return (
        <StyledHistory className="pt-110 pb-110">
            <Lines/>
            <Container className='history-title'>
                <SubTitle
                    color={'#58585A'}
                    margin={'0 0 40px 0'}
                    text={'history'}/>
                <Title
                    lineHeight={'50'}
                    margin={'0 0 40px 0'}
                    text={historyTitle}
                />
                <Text>
                    <p>
                        {historyData ? ReactHtmlParser(historyData.short_desc) : ''}
                    </p>
                </Text>
            </Container>

            <Container fluid className="history-slider p-0">
                {len && len === historyPostData.length ? (
                    <Swiper  {...params}>
                        {historyPostData.map(history => {
                            let historyData = history.data
                            let historyImage = history.images[0] ? history.images[0] : null

                            let historyImagePath = historyImage ?
                                baseUrl + historyImage.path + historyImage.img_name : ''
                            return (
                                <div key={history.data.id} className="history-slider__single">
                                    <div className="history-slider__single__content">
                                        <SubTitle
                                            text={historyData.title}
                                            fontWeight={'bold'}
                                            letterSpacing={'0'}
                                            lineHeight={'30'}
                                            color={'#000'}
                                            fontSize={'30'}
                                            margin={'90px 0 40px 0'}
                                        />
                                        <a href="/">
                                            <Title
                                                text={historyImage.short_desc}
                                                fontSize={'20'}
                                                fontWeight={'bold'}
                                                lineHeight={'30'}
                                                margin={'0 0 40px 0'}
                                            />
                                        </a>
                                    </div>
                                    <div className="history-slider__single__img">
                                        <a href="/"/>
                                        <img src={historyImagePath} alt=""/>
                                    </div>
                                    <Text margin={'40px 0 0 0'}>
                                        <p>
                                            {ReactHtmlParser(historyData.description)}
                                        </p>
                                    </Text>
                                </div>
                            )
                        })}
                    </Swiper>
                ) : ''}
            </Container>
        </StyledHistory>

    )
};


const StyledHistory = styled.div`
  position: relative;
   .swiper-container{
      padding-left: calc(170px + 55px);
   }
   .history-title{
    padding-left: 185px;
   }
   
   .history-slider{
   
       &__single{
           &__content{
              a{
              h2{
                transition:color .4s ease;
              }
                &:hover{
                    h2{
                    color: ${hover} !important;
                    }
                  
                }
              }
           }
           
            &__img{
                padding-top: 67.57%;
                position: relative;
                overflow: hidden;
                margin-top: 40px;
                a{
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  z-index: 3;
                }
                img{
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                  transform: scale(1.01);
                  top: 0;
                  left: 0;
                  transition:all 2s ease;
                  
                }
                &:hover{
                  img{
                    transform: scale(1.05);
                    filter:contrast(140%);
                  }
                }
           }
       }
      
    &__bottom{
     margin-top: -50px;
    }  
   }
   
  .swiper-button-next{
       position: absolute;
        right: 0px;
        bottom: 0px;
        margin: auto;
        z-index: 3;
        height: 50px;
        width: 50px;
        background-image: url(${RightIcon});
        background-position: center;
        background-size: contain;
        top: auto;
        left: auto;
         &:focus{
          outline:none;
        }
    }
    .swiper-button-prev{
       position: absolute;
        right: 60px;
        bottom: 0px;
        margin: auto;
        z-index: 3;
        height: 50px;
        width: 50px;
        top: auto;
        left: auto;
        background-image: url(${LeftIcon});
        background-size: contain;
        &:focus{
          outline:none;
        }
    }
  

   @media(max-width:991px){
    .history-title{
      padding-left: 15px;
    }
   }
   

   @media(max-width:767px){
    .history-slider__single__content{
      .sub-title{
        margin-top: 30px;
      }
    }
    .swiper-container{
      padding-left: 15px;
      padding-right: 15px;
      .title{
        font-size: 20px;
        margin-bottom: 0px;
      }
      .history-slider__single__img{
        margin-top: 15px;
      }
      p{
        margin-top: 20px;
        font-weight: 300;
      }
    }
   }
`;


export default History;

