import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import ModalVideo from 'react-modal-video'
import Title from '../../../components/Title';
import Lines from '../../../components/VerticalLine';
import $ from 'jquery';
// css
import "swiper/swiper.scss";
import '../../../../node_modules/react-modal-video/scss/modal-video.scss';

// img
import play from '../../../assets/images/play.svg';
import {connect} from 'react-redux'
import {baseUrl} from "../../../components/BaseUrl";
import PreloadImage from "react-preload-image";

const Videos = ({ slug, mediaPageList }) => {
  let [open, setOpen] = useState(false);
  let [videoId, setVideo] = useState('');

  let handelOpen = (open, id) => {
    setOpen(open);
    setVideo(id);
  };
  let mediaPage = mediaPageList ? mediaPageList.find(media => {
    if(media.page_data.slug === slug) return media
  }) : null;

  let videos = mediaPage ? mediaPage.images ? mediaPage.images.list : [] : []

  useEffect(()=>{
    $('.videos__single__img').on('click',function () {
      $('.menu-bar').css('z-index',3)
    });

    $('.modal-video-close-btn,.modal-video').on('click',function () {
      $('.menu-bar').removeAttr('style')
    })
  });



  return (
    <StyledVideos>
      {/*<Lines/>*/}
      <Container className="videos">
        <ModalVideo channel='youtube' isOpen={open} videoId={videoId}
                    onClose={() => handelOpen(false, '')}/>
        <Row>
          {videos.length ? videos.map(video => {
            let videoImage = baseUrl + video.path + video.img_name;
            return (
              <Col md={4}>
                <div className="videos__single">
                  <div className="videos__single__content">
                    <h4>video</h4>
                    <Title
                      text={video.short_title}
                      fontSize={'20'}
                      fontWeight={'bold'}
                      lineHeight={'30'}
                      margin={'0 0 40px 0'}
                    />
                  </div>
                  <div onClick={() => handelOpen(true, `${video ? video.extra_url : ''}`)} className="videos__single__img">
                    <a><img src={play} alt=""/> </a>
                    {/*<img src={videoImage} alt=""/>*/}
                    <PreloadImage style={{
                      position: 'absolute',
                      width: '100%',
                      height:'100%',
                      top:0,
                      left:0,
                      backgroundColor: '#DDD'
                    }} src={videoImage} />
                  </div>
                </div>
              </Col>
            )
          }) : null}
        </Row>
        <Row>
          <Col className='text-center'>
            {/*<div onClick={() => handleLoadMore()}>*/}
            {/*    <Button*/}
            {/*      width={'158px'}*/}
            {/*      text={'Load More'}*/}
            {/*    />*/}
            {/*</div>*/}
          </Col>
        </Row>
      </Container>
    </StyledVideos>

  )
};

const StyledVideos = styled.div`
  padding-top: 60px;
  padding-bottom: 110px;
  position: relative;
  .videos{
    &__single{
      margin-bottom: 60px;
      position: relative;
      z-index: 3;
      &__content{
        h4{
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 500;
          line-height: 21px;
          margin: 0 0 20px 0;
          color: rgba(9,32,59,0.50);
        }
        p{
          font-size: 20px;
          font-weight: bold;
          line-height: 30px;
          margin-bottom: 40px;
        }
        h2{
          width: 80%;
        }

      }

      &__img{
        padding-top: 67.57%;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        a{
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          z-index: 3;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(0,0,0,0.50);
          img {
            height: auto;
            width: auto;
            position: relative
          }
        }
        >img{
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          transform: scale(1.01);
          top: 0;
          left: 0;
          transition:all 2s ease;

        }
        &:hover{
          > img{
            transform: scale(1.05);
          }
        }
      }
    }

    &__bottom{
      margin-top: -50px;
    }

    .dc-btn{
      margin: auto;
      position: relative;
      z-index: 3;
      img{
        display: none;
      }
    }
  }

  @media(max-width:767px){
    .videos__single__content{
      h4{
        margin-bottom:10px;
      }
      .title{
        width: 100%;
        margin-bottom: 20px;
      }
    }
    .videos__single__img{
      margin-top: 20px;
    }
    .videos__single{
      margin-bottom: 40px;

    }
    .col-md-4{
      &:nth-last-of-type(1) .videos__single{
        margin-bottom: 40px;
      }
    }

  }
`;

function mapStateToProps(state) {
  return {
    mediaPageList: state.mediaReducer.mediaPageList
  }
}

export default connect(mapStateToProps)(Videos);

