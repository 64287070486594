import React, {Component} from 'react';
// components
import Slider from './components/BannerSlider'
import AboutUs from './components/AboutUs'
import HomeCounter from '../../components/NumberRows'
import OurConcern from './components/OurConcern'
import HomeTeam from './components/HomeTeam'
import NewsEvents from './components/NewsEvents'
import Sustainability from './components/Sustainability'
import HomeMap from './components/HomeMap'
import Menu from "../../components/Menu";
import LifeAt from "./components/LifeAt";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {Helmet} from "react-helmet";

class HomePage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Mir Group</title>
                </Helmet>
                <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                    <Menu/>
                    <Slider/>
                    <OurConcern/>
                    <AboutUs/>
                    <HomeCounter/>
                    <HomeTeam/>
                    <NewsEvents/>
                    <LifeAt/>
                </motion.div>
            </>
        );
    }
};

export default HomePage;



