import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Form} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import ButtonSubmit from '../../../components/ButtonSubmit';
//import {withGoogleMap, withScriptjs, GoogleMap, Marker, InfoWindow} from 'react-google-maps';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import InfoWindowEx from "../../home/components/InfoWindowEx";
import mapStyle from '../../../components/MapStyle';
import {MAP_API_KEY} from '../../../config/index'
import ContactForm from "./ContactForm";

// img
import leftBg from '../../../assets/images/sustain-bg.svg';
import rightArrow from '../../../assets/images/btn-arrow-black.svg';
import pointer from '../../../assets/images/pointer.svg';
import ReactHtmlParser from "react-html-parser";
import MapImage from '../../../assets/images/Mask Group 2@2x.png'

export class MapComponent extends Component{
  constructor(props) {
    super(props)
    this.state = {
      currLatt: 0,
      currLng: 0,
      showHelpLink: false,
      showingInfoWindow: false,  //Hides or the shows the infoWindow
      activeMarker: {},          //Shows the active marker upon click
      selectedPlace: {},
      windowWidth: 0,
      windowHeight: 0,
      markerIcon: [
        pointer,
      ],
      selectedMarkers: '',
      clickedID: false,
      connectionId: ''
    };
  }

  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  }

  onClose = () => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
        clickedID: false
      });
    }
  };

  render() {
    let { posts, title } = this.props

    let latData = posts ? posts.find(post => post.data.slug === 'lat') : null
    let lngData = posts ? posts.find(post => post.data.slug === 'lng') : null
    let addressData = posts ? posts.find(post => post.data.slug === 'address') : null
    let zoom = 14

    let mapData = {
      'id':1,
      'lat': latData ? parseFloat(latData.data.description) : null,
      'lng': lngData ? parseFloat(lngData.data.description) : null,
      'info': addressData ?
        `<div class="map-info"><h4>${title}</h4>`
        : ''
    }

    let pos = {'lat':mapData.lat,'lng':mapData.lng};

    return (
      <StyleDivisionMap>
        <Container fluid>
          {latData ? (
            <Row className='row-eq-height'>
              <Col sm={6} className="map-left p-0">
                <img src={MapImage} width={'100%'} />
                {/*<Map*/}
                {/*  google={this.props.google}*/}
                {/*  zoom={zoom}*/}
                {/*  initialCenter={pos}*/}
                {/*  onClick={this.onClose}*/}
                {/*  mapTypeControl={false}*/}
                {/*  styles={mapStyle}*/}
                {/*  fullscreenControl={false}*/}
                {/*>*/}
                {/*  <Marker*/}
                {/*    position={pos}*/}
                {/*    title={ReactHtmlParser(mapData.info)}*/}
                {/*    formatted_address={mapData.id}*/}
                {/*    name={ReactHtmlParser(mapData.info)}*/}
                {/*    icon={{*/}
                {/*      url: `${pointer}`*/}
                {/*    }}*/}
                {/*    onClick={this.onMarkerClick}*/}
                {/*  />*/}
                {/*  <InfoWindowEx*/}
                {/*    marker={this.state.activeMarker}*/}
                {/*    visible={this.state.showingInfoWindow}*/}
                {/*    onClick={this.onClose}*/}
                {/*  >*/}
                {/*    <div className="info-window">*/}
                {/*      <div>{this.state.selectedPlace.name}</div>*/}
                {/*    </div>*/}
                {/*  </InfoWindowEx>*/}
                {/*</Map>*/}
              </Col>
              <ContactForm link={pos} description={addressData ? addressData.data.description : ''} />
            </Row>
          ) : ''}
        </Container>
      </StyleDivisionMap>
    )
  }
};

const StyleDivisionMap = styled.div`
     .map-left{
        position: relative;
       >div{
          min-height: 100% !important;
          //position: absolute;
          width: 100%;
        }
     }
     .map-right{
        //background-color:#D6D6D6;
        position: relative;
        padding-left: 110px;
        padding-right: 100px;
      >img{
        height: 100%;
        width: 100%;
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
      }
      form{
        .from-group{
          margin-bottom: 25px;
        }
        input{
          border:none;
          border-bottom:1px solid #D1D1D1;
          background-color:transparent;
          border-radius: 0;
          &::placeholder{
            font-size: 14px;
            font-weight: 300;
            line-height: 21px;
            color: #989898;
          }
        }
        textarea{
           border:none;
           border-bottom:1px solid #D1D1D1;
           border-radius: 0;
           min-height: 107px;
           height: 107px;
           min-width: 100px;
           max-width: 100%;
            &::placeholder{
            font-size: 14px;
            font-weight: 300;
            line-height: 21px;
            color: #989898;
          }
        }
      }
     }
     .map-left{
      position: relative;
        >img{
          height: 100%;
          width: 100%;
          position: absolute;
          object-fit: cover;
          top: 0;
          left: 0;
        }
     }
     
     @media(max-width:768px){
    
      .map-left{
        min-width: 100%;
        max-height: 400px;
        height: 400px;
        overflow: hidden;
        >div{
          max-height: 400px;
        }
      }
      .map-right{
        min-width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        &__content{
          padding-right: 0;
          &:after{
            right: 0;
          }
        }
        .title{
          margin-bottom: 40px;
        }
      }
     }
     
     @media(max-width:767px){
       .sub-title{
         margin-bottom: 30px;
       }
       .dc-btn{
        margin-top: 60px;
       }
     }
`;


export default GoogleApiWrapper({
  apiKey: MAP_API_KEY
})(MapComponent);
