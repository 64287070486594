import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import Menu from "../../components/Menu";
import {fetchMediaPageData} from "../../redux/actions/media";
import {ApiParams} from "../../constants/apiParams";
import {connect} from 'react-redux';

import BannerImage from '../../assets/images/dynamic/inner-banner.jpg'
import {baseUrl} from "../../components/BaseUrl";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import Filter from './components/NewsFilter';
import {Loading} from "../../components/Loading";
import {Helmet} from "react-helmet";

class NewsEvents extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);

        if (!this.props.mediaPage.length) {
            let param = {
                [ApiParams.page_type]: 'news'
            }
            this.props.getMediaPageData(param)
        }
        if (this.props.sectionId) {
            let elem = document.getElementById(this.props.sectionId)
            if (elem) elem.scrollIntoView({behavior: "smooth", block: "start"})
        }
    }

    render() {
        let {mediaPage} = this.props

        let mediaData = mediaPage ? mediaPage.page_data : null
        let mediaPageSub = mediaData ? mediaData.title : ''
        let mediaPageTitle = mediaData ? mediaData.sub_title : ''
        let mediaPageText = mediaData ? mediaData.short_desc : ''

        let mediaPageImg = mediaPage ?
            mediaPage.images ?
                mediaPage.images.list ? mediaPage.images.list[0] : null
                : null
            : null

        let mediaBanner = mediaPageImg ? baseUrl + mediaPageImg.path + mediaPageImg.img_name : ''

        return (
            <>
                <Helmet>
                    <title>News And Events | Mir Group</title>
                </Helmet>
                <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                    <Menu/>
                    {this.props.mediaPageLoading && <Loading/>}
                    <InnerBanner
                        title={'news and events'}
                        // subTitle={mediaPageSub}
                        // text={mediaPageText}
                        image={mediaBanner}
                    />
                    {mediaData ?
                        <Filter page_id={mediaData.id}/>
                        : <div style={{minHeight: '50vh'}}/>
                    }
                </motion.div>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        mediaPage: state.mediaReducer.mediaPage,
        mediaPageLoading: state.mediaReducer.mediaPageLoading,
        sectionId: state.menuReducer.sectionId
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getMediaPageData: params => dispatch(fetchMediaPageData(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsEvents);
