import React, {Component, useEffect, useLayoutEffect} from 'react';
import InnerTextBanner from '../../components/InnerTextBanner';
import Description from './components/Description';
import Menu from "../../components/Menu";
import logoBlack from '../../assets/images/logo-black.svg';
import hamburderBlack from '../../assets/images/hamburger-black.svg';
import {connect} from 'react-redux'
import {fetchMediaDetailsData} from "../../redux/actions/media";
import {ApiParams} from "../../constants/apiParams";
import {withRouter} from 'react-router-dom'
import {baseUrl} from "../../components/BaseUrl";
import moment from 'moment'
import {Loading} from "../../components/Loading";
import {Helmet} from "react-helmet";

class NewsDetail extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);

        let params = {
            [ApiParams.page_slug]: this.props.match.params.slug
        }
        this.props.getMediaDetailsData(params)
    }

    render() {
        let {mediaPageDetails} = this.props;
        let parentTitle = mediaPageDetails ? mediaPageDetails.page_data ? mediaPageDetails.page_data.title : [] : [];
        let posts = mediaPageDetails ? mediaPageDetails.posts ? mediaPageDetails.posts.list : [] : [];

        let activePost = posts ? posts.find(post => post.data.slug === this.props.match.params.post_slug) : null

        let postData = activePost ? activePost.data : null
        let postImage = activePost ?
            activePost.images ?
                activePost.images[0] ? baseUrl + activePost.images[0].path + activePost.images[0].img_name : ''
                : ''
            : '';

        let title = postData ? postData.title : '';
        let date = postData ? moment(postData.updated_at).format('DD.MM.YYYY') : '';


        return (
           <>
               <Helmet>
                   {
                       title ?
                           <title>{title}</title>
                           : <title>{this.props.match.params.slug} | Mir Group</title>
                   }
               </Helmet>
               <div>
                   <Menu/>
                   {this.props.mediaPageLoading && <Loading/>}
                   <Description
                       parentTitle={parentTitle}
                       title={title}
                       date={date}
                       aboutImg={postImage}
                       postData={postData}
                   />
               </div>
           </>
        )
    }
};

function mapStateToProps(state) {
    return {
        mediaPageDetails: state.mediaReducer.mediaPageDetails,
        mediaPageLoading: state.mediaReducer.mediaDetailsLoading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getMediaDetailsData: params => dispatch(fetchMediaDetailsData(params))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewsDetail));
