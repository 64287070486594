import React, {useEffect, useLayoutEffect} from 'react';
import InnerTextBanner from '../../components/InnerTextBanner';
import Description from './components/Description';
import Menu from "../../components/Menu";
import logoBlack from '../../assets/images/logo-black.svg';
import hamburderBlack from '../../assets/images/hamburger-black.svg';
import {Helmet} from "react-helmet";

const NewsDetail = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>Sustainability | Mir Group</title>
            </Helmet>
            <div>
                <Menu img={hamburderBlack} logo={logoBlack} color={'#000'}/>
                <InnerTextBanner title={'Sustainability'}/>
                <Description/>
            </div>
        </>
    )
};

export default NewsDetail;
