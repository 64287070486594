import Loader from '../assets/images/loader.gif';

export const Loading = () => {
    return (
        <div className='loading-before-content'>
            <div className="loadingio-spinner-rolling-qx6ynrq7t2">
                <div className="ldio-2p01d405ya4">
                    <div/>
                </div>
            </div>
        </div>
    )
};