import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Form, Col, Row, Table} from "react-bootstrap";
import AboutImg from '../../../assets/images/inner.png';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import Button from '../../../components/Button';
import {hover, darkGreen, lightGreen} from "../../../globalStyleVars";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom'
import {fetchFormData, postFormSubmit} from "../../../redux/actions/form";
import {ApiParams} from "../../../constants/apiParams";
import Message from '../../../components/PopupMessage';
import InnerBanner from "../../../components/InnerBanner";
import ReactHtmlParser from "react-html-parser";


class JobDescription extends Component {

  state = {
    name: '',
    contact_no: '',
    email: '',
    address: '',
    cv: '',
    message: ''
  }

  handleState = e => {
    if(e.target.name !== 'cv') {
      this.setState({ [e.target.name]: e.target.value})
    } else {
      this.setState({[e.target.name]: e.target.files[0]})
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    let formField = {
      form_id: 'career-form'
    }
    let len = this.props.formFields.length

    for(let i = 0; i < len; i++) {
      formField[this.props.formFields[i]["field_key"]] = ""
    }

    formField["name"] = this.state.name
    formField["contact_no"] = this.state.contact_no
    formField["email"] = this.state.email
    formField["cv"] = this.state.cv
    // formField["message"] = this.state.message
    formField["address"] = this.state.address

    // console.log('FormField', formField)

    let bodyFormData = new FormData()

    for(let key in formField) {
      bodyFormData.append(`${key}`, formField[key])
    }

    this.props.submitForm(bodyFormData)
    if(!this.state.formSubmitLoading) {
      this.setState({
        name: '',
        contact_no: '',
        email: '',
        address: '',
        cv: '',
        message: ''
      })
    }
  }

  componentDidMount() {
    let param = {
      [ApiParams.form_slug]: 'career-form'
    }
    this.props.getFormData(param)
  }

  render() {
    let { footerOffset, formFields, submitForm,formSubmitErr } = this.props

    let JD = this.props.location.state ? this.props.location.state : null

    return (
      <StyledJobDescription offset={footerOffset}>
        <Message
          title={formSubmitErr ? 'Error' : 'Form Submitted'}
        />
        <InnerBanner
          image={AboutImg}
          title={'Apply For Job'}
        />
        <div className="description-content">
          <div className="description__wrap">
            <div className="description__wrap__about">
              <Container>
                <Row>
                  <Col md={{ span: 7}} xs={12}>
                    <Title
                        text={JD ? JD.title : ''}
                        color={'#304550'}
                        margin={'0 0 20px 0'}
                        fontSize={28}
                    />
                    <Table responsive>
                      {JD ?  ReactHtmlParser(JD.description) : ''}
                    </Table>
                  </Col>
                  <Col className="form-field" md={{ span: 4, offset: 1}} xs={12}>
                    <Title
                        text={'Apply Now'}
                        fontWeight={'bold'}
                        fontSize={'40'}
                        lineHeight={'42'}
                        color={'#304550'}
                        margin={'0 0 52px 0'}
                    />

                    <Form onSubmit={this.handleSubmit}>
                      <Form.Group>
                        <Row>
                          <Col md={12}>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder="Enter your name here"
                                name="name"
                                value={this.state.name}
                                onChange={this.handleState}
                            />
                          </Col>
                          <Col md={12}>
                            <Form.Label>Email ID</Form.Label>
                            <Form.Control
                                type='email'
                                placeholder="Enter your email ID here"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleState}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group>
                        <Row>
                          <Col md={12}>
                            <Form.Label>Contact number</Form.Label>
                            <Form.Control
                                type='number'
                                placeholder="Enter your contact number here"
                                name="contact_no"
                                value={this.state.contact_no}
                                onChange={this.handleState}
                            />
                          </Col>

                          <Col md={12}>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder="Enter your address here"
                                name="address"
                                value={this.state.address}
                                onChange={this.handleState}
                            />
                          </Col>
                          <Col md={12} className='value-added'>
                            <Form.Label>Position</Form.Label>
                            <Form.Control
                                type="text"
                                value={JD ? JD.title : ''}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group>
                        <Row>
                          <Col>
                            <Form.Label>Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder='Enter your message here'
                                name="message"
                                value={this.state.message}
                                onChange={this.handleState}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group>
                        <Row>
                          <Col md={12}>
                            <Form.Label>Upload CV</Form.Label>
                            <Form.File
                                className="input-file"
                                placeholder='.pdf/.docx'
                                name="cv"
                                onChange={this.handleState}
                            />
                            {this.state.cv.name ? <p>{this.state.cv.name}</p> : ''}
                          </Col>
                          <Col md={12}>
                            <Button
                                text={'submit'}
                                onSubmit={this.handleSubmit}
                                fontSize={'16'}
                                color={'#FFF'}
                                letterSpacing={'0'}
                                width={'100%'}
                                textAlign={'center'}
                                noArrow={true}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>

      </StyledJobDescription>
    )
  }
};

const StyledJobDescription = styled.div`
  .description-content{
    background-color: #fff;
    .description{
      &__img{
        height: 600px;
        position: relative;
        img{
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
        }
      }
    }
  }
  .description__wrap{
    background: #FFF;
    width: 100%;
    //padding: 100px;
    position: relative;
    z-index: 2;
    .form-field {

    }
    form{
      label{
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        margin-bottom: 10px;
      }
      .form-group{
        margin-bottom: 30px;
      }
      .form-control{
        height: 50px;
        line-height: 50px;
        border-radius: 0;
        border-color:#D9D9D9;
        padding-left: 20px;
        &::placeholder{
          font-size: 16px;
          font-weight: 300;
          line-height: 21px;
          color: rgba(0,0,0,0.5);
        }
      }
      textarea{
        min-height: 250px;
        max-height: 250px;
        max-width: 100%;
        min-width: 100%;
        line-height: unset !important;
        padding-top: 14px;
        padding-left: 20px;
      }

      .input-file{
        position: relative;
        height: 50px;
        cursor: pointer;
        //opacity: 0;
        &:after{
          cursor: pointer;
          content: 'Upload CV/Drag your CV';
          position: absolute;
          height: 50px;
          width: 100%;
          top: 0;
          background: #FFF;
          border: 1px solid #D9D9D9;
          z-index: -1;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(0,0,0,0.5);
        }
      }
      .form-control-file{
        cursor: pointer;
        opacity: 0;
      }
      .dc-btn{
        margin-top: 33px;

        a{
          background-color: ${darkGreen};
          text-transform: uppercase;
          transition: all .2s ease-in;
          border: none;
          &:hover{
            background-color: ${hover};
            border-color: ${hover};
          }
          &:after{
            background-color: ${darkGreen};
          }
        }
        img{
          display: none;
        }
      }

      p{
        color: rgba(0,0,0,0.51);
      }
    }
  }

  .dc-btn{
    cursor: pointer;
  }

  .value-added{
    input{
      color: rgba(0,0,0,0.3);
    }

  }

  @media(max-width:1080px){
    .container{
      overflow-x: hidden;
    }
    overflow-x: hidden;
  }
  @media(max-width:1080px){
    .description__wrap{
      overflow: hidden;
      width: 100%;
    }
  }

  @media(max-width:992px){
    .description__wrap{
      padding: 80px 35px;

    }
    .description__img {
      height: 500px !important;
      position: relative;
    }
  }


  @media(max-width:767px){

    .description__wrap{
      padding: 80px 20px;
      img{
        margin-top: 10px;
        margin-bottom: 30px;
      }
    }
    .description__img {
      height: 500px;
      position: relative;
    }
    p{
      font-size: 16px;
      line-height: 24px;
    }
    .description__wrap form{
      .form-group{
        margin-bottom: 20px;
        .col-md-6{
          &:nth-of-type(1){
            margin-bottom: 20px;
          }
        }
      }
      .dc-btn{
        margin-top: 10px;
      }
    }

  }
`;

let mapStateToProps = state => {
  return {
    footerOffset: state.footerReducer.footerOffset,
    formFields: state.formReducer.formFields,
    formSubmitErr: state.formReducer.formSubmitErr,
    formSubmitLoading: state.formReducer.formSubmitLoading
  }
};

let mapDispatchToProps = dispatch => {
  return {
    getFormData: params => dispatch(fetchFormData(params)),
    submitForm: body => dispatch(postFormSubmit(body))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobDescription));
