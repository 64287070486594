import React, {useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Form} from "react-bootstrap";
import Title from '../../../components/Title';
import SubTitle from '../../../components/SubTitle';
import Text from '../../../components/Text';
import Button from "../../../components/Button";
import {hover, darkGreen, lightGreen} from "../../../globalStyleVars";
import Select from 'react-select';
import {postFormSubmit} from "../../../redux/actions/form";
import {connect} from 'react-redux';
import Message from '../../../components/PopupMessage';
import MapButton from "../../../components/MapButton";

const options = [
  { value: 'Washing', label: 'Washing' },
  { value: 'Knitting', label: 'Knitting' },
  { value: 'Printing', label: 'Printing' }
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
    backgroundColor:state.isSelected ? '#1EC3AA' : '#FFF',
    margin:0
  }),
  menu: (provided, state) => ({
    ...provided,
    color: 'rgba(0,0,0,0.5)',
    backgroundColor:state.isSelected ? '#000' : '#FFF',
    margin:0
  }),
  menuList:(provided, state) => ({
    ...provided,
    backgroundColor:state.isSelected ? '#000' : '#FFF',
    borderRadius: 0,
  }),

};


const BannerDescription = (props) => {
  let { formFields, submitForm, formSubmitErr, address } = props;
  let [state, setState] = useState({
    name: '',
    phone: '',
    email: '',
    message: ''
  })

  let handleChange = e => {
    let value = e.target.value
    setState({
      ...state,
      [e.target.name]: value
    })
  }

  let handleInit = () => {
    setState({
      name: '',
      phone: '',
      email: '',
      message: ''
    })
  }

  let handleSubmit = (e) => {
    e.preventDefault()
    let formField = {
      form_id: 'contact-form'
    }
    let len = formFields.length

    for(let i = 0; i < len; i++) {
      formField[formFields[i]["field_key"]] = ""
    }

    formField["name"] = state.name
    formField["phone"] = state.phone
    formField["email"] = state.email
    formField["message"] = state.message

    let bodyFormData = new FormData()

    for(let key in formField) {
      bodyFormData.append(`${key}`, formField[key])
    }

    submitForm(bodyFormData)
    handleInit()
  };


  return (
    <StyledContactForm className="pb-110">
      <Message
        title={formSubmitErr ? 'Error' : 'Form Submitted'}
      />
      <Container>
        <Row className="contact-row">
          <Col md={{span: 12}}>
            <div className="contact-form">
              <Title
                text={'Get in touch'}
                margin={'0 0 50px 0'}
                fontSize={36}
                color={'#304550'}
              />
              <div className="contact-form__form">
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder="Enter your name here"
                      name="name"
                      value={state.name}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Email ID</Form.Label>
                    <Form.Control
                      type='email'
                      placeholder="Enter your email address here"
                      name="email"
                      value={state.email}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Contact number</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder="Enter your contact number here"
                      name="phone"
                      value={state.phone}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder='Enter your message here'
                      name="message"
                      value={state.message}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Button
                      text={'submit'}
                      onSubmit={handleSubmit}
                      fontSize={'16'}
                      color={'#FFF'}
                      letterSpacing={'0'}
                      width={'100%'}
                      textAlign={'center'}
                      noArrow={true}
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </StyledContactForm>
  )
};

const StyledContactForm = styled.div`
  .contact-row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .col-md-12 {
    max-width: 70%;
  }
  a{
    color: ${hover};
    &:hover{
      color: ${darkGreen};
    }

  }
  .contact-form{
    background-color: #fff;
    padding: 100px;
    &__form{
      form{
        label{
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          margin-bottom: 10px;
        }
        .form-group{
          margin-bottom: 30px;
          &:nth-last-of-type(1){
            margin-bottom: 0 !important;
          }
          &:hover{
            cursor: pointer;
          }
        }
        .form-control{
          height: 50px;
          line-height: 50px;
          border-radius: 0;
          border-color:#D9D9D9;
          padding-left: 20px;
          &:focus{
            border-color:${hover};
          }
          &::placeholder{
            font-size: 16px;
            font-weight: 300;
            line-height: 21px;
            color: rgba(0,0,0,0.5);
          }
        }
        textarea{
          min-height: 150px;
          max-height: 150px;
          max-width: 100%;
          min-width: 100%;
          line-height: unset !important;
          padding-top: 14px;
          padding-left: 20px;
        }

        .input-file{
          position: relative;
          height: 50px;
          //opacity: 0;
          &:after{
            content: 'Upload CV/Drag your CV';
            position: absolute;
            height: 50px;
            width: 100%;
            top: 0;
            background: #FFF;
            border: 1px solid #D9D9D9;
            z-index: -1;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(0,0,0,0.5);
          }
        }
        .form-control-file{
          cursor: pointer;
          opacity: 0;
        }
        .dc-btn{
          margin-top: 33px;

          a{
            background-color: ${darkGreen};
            text-transform: uppercase;
            transition: all .2s ease-in;
            &:hover{
              background-color: ${hover};
            }
            &:after{
              background-color: ${darkGreen};
            }
          }
          img{
            display: none;
          }
        }
      }
    }
  }



  @media(max-width:1000px){
    .contact-form{
      padding: 100px 30px 100px 30px;
    }

  }

  @media(max-width:767px){
    //background-color:#FFF;
    padding-bottom: 0 !important;
    .container{
      padding-left: 0;
      padding-right: 0;
      .col-md-4{
        padding-left: 30px;
        padding-right: 30px;
      }
      .col-md-12 {
        max-width: 100%;
      }
    }
    .contact-form{
      padding: 80px 15px 80px 15px;
      margin-top: 80px;
      .form-group{
        margin-bottom: 20px !important;
      }
    }


  }

`;

function mapStateToProps(state) {
  return {
    formFields: state.formReducer.formFields,
    formSubmitErr: state.formReducer.formSubmitErr,
    formSubmitLoading: state.formReducer.formSubmitLoading,
    address: state.contactReducer.address
  }
}

function mapDispatchToProps(dispatch) {
  return {
    submitForm: body => dispatch(postFormSubmit(body))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerDescription);
