import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Table} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import AboutImg from '../../../assets/images/dynamic/about-detail.jpg';

import imgs from '../../../assets/images/dynamic/acheivement.jpg'
import {connect} from "react-redux";

const Description = ({footerOffset}) => {
    return (
        <StyledDescription offset={footerOffset}>
            <div className="description-content">
                <div className="description__img">
                    <img src={AboutImg} alt=""/>
                </div>

                <div className="description__wrap">
                    <div className="description__wrap__about">
                        <p>Each of our products and services are delivered through the assurance of sustainability and
                            safety protocols. We make use of machineries and materials that do not harm the environment.
                            Through recycling of solid waste and utilization of ETP water systems, we ensure complete
                            protection of the external as well as our internal environment.</p>

                    </div>


                </div>

            </div>

        </StyledDescription>
    )
};

const StyledDescription = styled.div`
  .description-content{
    background-color: #f9f9f9;
    .description__img{
      height: 600px;
      position: relative;
      img{
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
       }
    }
  }
  .description__wrap{
     background: #FFF;
     width: calc(100% - ${props => props.offset + 15}px);
     margin-left: ${props => props.offset + 15 || 200}px;
     padding: 100px;
     margin-top: -200px;
     position: relative;
     z-index: 2;
    img {
      width: 100%;
      object-fit: contain;
      position: relative;
      height: auto;
      margin-bottom: 40px;
      margin-top: 20px;
    }
    p{
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 20px;
      &:nth-last-of-type(1){
        margin-bottom: 0;
      }
    }
  }
  
  @media(max-width:1080px){
    .container{
      overflow-x: hidden;
    }
    overflow-x: hidden;
  }
  @media(max-width:1080px){
       .description__wrap{
       overflow: hidden;
        width: calc(100% - 15px);
        margin-left: 15px;
      }
  }
 
  @media(max-width:992px){
      .description__wrap{
        margin-top: -100px;
        padding: 80px 35px;
      }
       .description__img {
            height: 500px;
            position: relative;
        }
  }
 
  
  @media(max-width:767px){
       
      .description__wrap{
        padding: 80px 20px;
        img{
          margin-top: 10px;
          margin-bottom: 30px;
        }
      }
      .description__img {
        height: 500px;
        position: relative;
      }
      p{
        font-size: 16px;
        line-height: 24px;
      }
      .description__wrap__at-a-glance{
        margin-top: 60px;
        padding-top: 60px;
        table tr td{
         padding-top: 15px;
         padding-bottom: 15px;
         font-size: 16px !important;
         line-height: 21px !important;
        }
      }
      
  }
 
`;

let mapStateToProps = state => {
    return {
        footerOffset: state.footerReducer.footerOffset
    }
};

export default connect(mapStateToProps)(Description);