import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import TabItems from './TabItems'
import {ApiParams} from "../../../constants/apiParams";
import Select from 'react-select';
import { connect } from 'react-redux'
import { fetchDivisionListData, fetchDivisionListChildData } from '../../../redux/actions/division'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
    backgroundColor:state.isSelected ? '#1EC3AA' : '#FFF',
    margin:0
  }),
  menu: (provided, state) => ({
    ...provided,
    color: 'rgba(0,0,0,0.5)',
    backgroundColor:state.isSelected ? '#000' : '#FFF',
    margin:0
  }),
  menuList:(provided, state) => ({
    ...provided,
    backgroundColor:state.isSelected ? '#000' : '#FFF',
    borderRadius: 0,
  }),

};



class DivisionTab extends Component{
  state = {
    activeTab: 0,
    tab_id: null,
    clicked: false
  };

  componentDidMount() {
    let params = {
      [ApiParams.page_id]: this.props.page_id
    }

    this.props.getDivisionListData(params)
  }

  handleTabChange = (key, tabId) => {
    this.setState({ activeTab: key, tab_id: tabId, clicked: true })
    let params = {
      [ApiParams.page_id]: tabId
    }
    this.props.getDivisionListChildData(params)
  };

  render() {
    let { divListData, divListChild } = this.props;
    let tabFilter = divListData ? divListData.map(data => data.page_data) : []
    let firstTabId = tabFilter[0] ? tabFilter[0].id : null

    let mobileOption = tabFilter? tabFilter.map(tab => {
      let data = {
        value: tab.id,
        label: tab.title
      }
      return data
    }) : []

    return(
      <div>
        {firstTabId ?
          <TabItems
            divListChild={divListData}
          /> : null
        }
      </div>
    )
  }
}


// filter
const StyledFilter = styled.div`
  background-color: #F3FCFB;
  padding:50px 0;
  .division-filter__menu{
    display: flex;
    justify-content: space-between;
    li{

      a{
        font-size: 30px;
        font-weight: bold;
        line-height: 30px;
        color: rgba(0,0,0,0.15);
        display: block;
        text-transform: capitalize;
        transition:color .3s ease;
        cursor: pointer;
        &:hover{
          color: #000;
        }
      }
      &.active a{
        color: #000;
      }
    }
  }

  @media(max-width:992px){
    .division-filter__menu li a{
      font-size: 25px;
    }
  }

  @media(max-width:767px){
  .division-filter__menu{
    display: none;
  }
    &.division-filter{
    .sub-title{
      margin-bottom: 10px;
    }
      .container{
        overflow: visible !important;
      }
    }
  }
  .css-12dduw1-menu{
    z-index: 9;
  }
  .css-g1d714-ValueContainer{
    padding-left: 0;
  }
  .css-yk16xz-control,.css-1pahdxg-control{
    padding-left: 0;
     border:none;
    border-bottom:1px solid #D9D9D9 !important;
    background-color: transparent !important;
  }
  .css-tlfecz-indicatorContainer{
    padding-right: 0 !important;
    margin-right: 0 !important;
  }
   .css-1uccc91-singleValue{
    font-size: 20px;
    font-weight: bold;
   }
    
    @media(min-width:768px){
      .mobile-filter{
        display: none;  
      }
    }
  
`;

function mapStateToProps(state) {
  return {
    divListData: state.divisionReducer.divListData,
    divListChild: state.divisionReducer.divListChild
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getDivisionListData: params => dispatch(fetchDivisionListData(params)),
    getDivisionListChildData: params => dispatch(fetchDivisionListChildData(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DivisionTab);
