import React from 'react';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';
import hambuergerWhite from "../assets/images/close.svg";
import {baseUrl} from "./BaseUrl";

const ImageBox = ({
                    bgSrc,
                    logoSrc,
                    height,
                    text,
                    contenText,
                    fontSize,
                    fontWeight,
                    color,
                    letterSpacing,
                    lineHeight,
                    textTransform,
                    margin,
                    link
                  }) => {
  return (
    <StyledLink
      className={``}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      lineHeight={lineHeight}
      letterSpacing={letterSpacing}
      textTransform={textTransform}
      margin={margin}
      href={link}
      backgroundImage={bgSrc}
    >
      <StyleContentDiv
        className={`concern-content`}
      >
        <h3>{text}</h3>
        <div className="image-list">
          {logoSrc.map((item, key) => {
            if(key > 0) return (
              <img src={baseUrl + item.path + item.img_name} alt=""/>
            )
          })}
        </div>
      </StyleContentDiv>

    </StyledLink>

  )
};


const StyledLink = styled.a`
  font-size: ${props => props.fontSize || 42}px;
  font-weight: ${props => props.fontWeight || 'bold'};
  margin: ${props => props.margin || '0px'};;
  line-height: ${props => props.lineHeight || 42}px;
  color: ${props => props.color || '#000000'};
  text-transform: ${props => props.textTransform || 'capitalize'};
  display: block;

  border: 1px solid #E6E6E6;
  
  &:hover {
    background-image: url(${props => props.backgroundImage});
    background-size: cover;
    background-position: center center;

    .concern-content {
      background-color: rgba(48, 69, 80, .8);

      h3 {
        color: #fff;
      }
    }

    .concern-img {
      display: block;
    }

  }


  @media (max-width: 767px) {


  }
`;

const StyleContentDiv = styled.div`

  padding: 30px;
  min-height: 250px;

  h3 {
    font-size: 16px;
    color: #008C44;
    text-transform: uppercase;
  }

  .image-list {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 15px;

    img {
      position: relative;
      width: 60px;
      margin-right: 10px;
    }
  }
  
`;


export default ImageBox;
