import {MENU} from "../../constants/menu";

let initialState = {
  sectionId: null
}

export default function menuReducer(state = initialState, action) {
  switch (action.type) {
    case MENU.SCROLL_TO_VIEW.MAIN:
      return {
        ...state,
        sectionId: null
      }
    case MENU.SCROLL_TO_VIEW.SUCCESS:
      return {
        ...state,
        sectionId: action.value
      }

    case MENU.SCROLL_TO_VIEW.FAILURE:
      return {
        ...state,
        sectionId: null
      }

    default:
      return state
  }
}
