import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {connect} from 'react-redux';
import {setFooterOffset} from '../redux/actions/footer';
import FooterLogo from '../assets/images/logo-footer.svg';
import {hover, darkGreen} from "../globalStyleVars";
import {Link} from "react-router-dom";
import {setSectionId} from "../redux/actions/menu";

const Footer = (props) => {
    let {setSectionId} = props
    useEffect(() => {
        let elem = document.getElementById("main-container")
        if (elem && elem.offsetLeft) {
            props.getFooterOffset(elem.offsetLeft)
        }
    }, []);

    let fbIcon = (pathColor = '#304550') => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="7.595" height="16.3" viewBox="0 0 7.595 16.3">
                <path id="Path_26" data-name="Path 26"
                      d="M1014.169,3920.336v-1.4a3.03,3.03,0,0,1,.03-.49.954.954,0,0,1,.134-.341.549.549,0,0,1,.336-.232,2.419,2.419,0,0,1,.619-.064h1.4V3915h-2.245a3.653,3.653,0,0,0-2.8.925,3.894,3.894,0,0,0-.851,2.725v1.691h-1.682v2.809h1.681v8.15h3.373v-8.15h2.246l.3-2.809Z"
                      transform="translate(-1009.116 -3914.995)" fill={pathColor}/>
            </svg>
        )
    };

    let linkedinIcon = (pathColor = '#304550') => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="13.5" height="12.9" viewBox="0 0 13.5 12.9">
                <path id="LinkedIn"
                      d="M1100.935,3924.215v4.991h-2.893v-4.657c0-1.17-.419-1.968-1.466-1.968a1.582,1.582,0,0,0-1.484,1.058,1.977,1.977,0,0,0-.1.706v4.861h-2.9s.039-7.887,0-8.7h2.894v1.234c-.006.009-.014.019-.019.028h.019v-.028a2.874,2.874,0,0,1,2.608-1.438C1099.507,3920.3,1100.935,3921.542,1100.935,3924.215Zm-11.863-7.909a1.508,1.508,0,1,0-.038,3.008h.02a1.509,1.509,0,1,0,.019-3.008Zm-1.465,12.9h2.893v-8.7h-2.893Z"
                      transform="translate(-1087.435 -3916.306)" fill={pathColor}/>
            </svg>
        )
    };

    let twitterIcon = (pathColor = '#304550') => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="12.909" height="10.625" viewBox="0 0 12.909 10.625">
                <path id="Path_2113" data-name="Path 2113" d="M1237.574,104.23a5.331,5.331,0,0,1-1.306.372c.21-.035.518-.415.642-.569a2.4,2.4,0,0,0,.433-.79c.011-.023.02-.051,0-.068a.075.075,0,0,0-.069.006,6.668,6.668,0,0,1-1.548.592.1.1,0,0,1-.107-.029,1.258,1.258,0,0,0-.135-.138,2.736,2.736,0,0,0-.75-.459,2.609,2.609,0,0,0-1.15-.185,2.745,2.745,0,0,0-1.091.308,2.817,2.817,0,0,0-.88.719,2.7,2.7,0,0,0-.525,1.053,2.844,2.844,0,0,0-.028,1.111c.008.062,0,.071-.053.062a8.268,8.268,0,0,1-5.319-2.707c-.062-.071-.1-.071-.147.005a2.7,2.7,0,0,0,.46,3.186c.1.1.212.2.327.288a2.711,2.711,0,0,1-1.026-.288c-.062-.039-.094-.017-.1.054a1.62,1.62,0,0,0,.017.3,2.727,2.727,0,0,0,1.681,2.174,1.583,1.583,0,0,0,.341.1,3.04,3.04,0,0,1-1.007.031c-.073-.014-.1.023-.073.093a2.831,2.831,0,0,0,2.115,1.771c.1.017.192.017.288.039-.006.009-.012.009-.017.017a3.347,3.347,0,0,1-1.444.765,5.171,5.171,0,0,1-2.194.281c-.118-.017-.143-.016-.174,0s0,.048.034.079c.15.1.3.186.457.271a7.231,7.231,0,0,0,1.466.586,7.811,7.811,0,0,0,7.582-1.773,7.956,7.956,0,0,0,2.1-5.8c0-.083.1-.129.157-.174a5.128,5.128,0,0,0,1.032-1.073.327.327,0,0,0,.068-.205v-.011C1237.627,104.2,1237.626,104.207,1237.574,104.23Z" transform="translate(-1224.718 -102.957)" fill="#fff"/>
            </svg>
        )
    };

    let instagramIcon = (pathColor = '#304550') => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="12.897" height="12.894" viewBox="0 0 12.897 12.894">
                <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram"
                      d="M6.445,5.379A3.306,3.306,0,1,0,9.751,8.685,3.3,3.3,0,0,0,6.445,5.379Zm0,5.455A2.149,2.149,0,1,1,8.594,8.685a2.153,2.153,0,0,1-2.149,2.149Zm4.212-5.59a.771.771,0,1,1-.771-.771A.769.769,0,0,1,10.657,5.244Zm2.19.783a3.816,3.816,0,0,0-1.042-2.7A3.841,3.841,0,0,0,9.1,2.283c-1.065-.06-4.255-.06-5.32,0a3.836,3.836,0,0,0-2.7,1.039A3.828,3.828,0,0,0,.04,6.023c-.06,1.065-.06,4.255,0,5.32a3.816,3.816,0,0,0,1.042,2.7,3.846,3.846,0,0,0,2.7,1.042c1.065.06,4.255.06,5.32,0a3.816,3.816,0,0,0,2.7-1.042,3.841,3.841,0,0,0,1.042-2.7c.06-1.065.06-4.253,0-5.317Zm-1.375,6.459a2.176,2.176,0,0,1-1.226,1.226,14.21,14.21,0,0,1-3.8.259,14.321,14.321,0,0,1-3.8-.259,2.176,2.176,0,0,1-1.226-1.226,14.21,14.21,0,0,1-.259-3.8,14.321,14.321,0,0,1,.259-3.8A2.176,2.176,0,0,1,2.644,3.658a14.211,14.211,0,0,1,3.8-.259,14.321,14.321,0,0,1,3.8.259,2.176,2.176,0,0,1,1.226,1.226,14.21,14.21,0,0,1,.259,3.8A14.2,14.2,0,0,1,11.471,12.486Z"
                      transform="translate(0.005 -2.238)" fill={pathColor}/>
            </svg>
        )
    };

    let youtubeIcon = (pathColor = '#304550') => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10.556" viewBox="0 0 15 10.556">
                <path id="Path_29" data-name="Path 29"
                      d="M1180.9,3919.73c-.542-.644-1.541-.906-3.451-.906h-6.93c-1.953,0-2.969.28-3.509.965-.526.668-.526,1.653-.526,3.015v2.6c0,2.639.624,3.979,4.035,3.979h6.93c1.656,0,2.573-.232,3.166-.8.609-.583.869-1.533.869-3.18v-2.6C1181.487,3921.368,1181.447,3920.377,1180.9,3919.73Zm-4.786,4.731-3.147,1.645a.484.484,0,0,1-.708-.429V3922.4a.484.484,0,0,1,.707-.43l3.147,1.634a.484.484,0,0,1,0,.859Z"
                      transform="translate(-1166.487 -3918.824)" fill={pathColor}/>
            </svg>
        )
    };


    return (
        <StyledFooter id="footer" className="footer">
            <div className="footer-top-container">
                <img src={FooterLogo} alt=""/>
            </div>
            <div className="footer-bottom-container">
                <Container>
                    <Row>
                        <Col md={6} xs={12} className="footer__bottom">
                            <p>{new Date().getFullYear()} Mir Group | Designed & Developed by <a target="_blank"
                                                                           href="http://dcastalia.com/">Dcastalia</a>
                            </p>
                        </Col>
                        <Col md={6} xs={12} className="footer-icon">
                            <div className="icon-container">
                                <span className="icon-single">
                                    <a target="_blank" href="https://www.facebook.com/mirgroupfb">
                                        {fbIcon('#304550')}
                                    </a>
                                </span>
                                <span className="icon-single">
                                    <a target="_blank" href="https://www.linkedin.com/company/mirgroupbangladesh/?viewAsMember=true">
                                    {/*<a target="_blank" href="https://www.linkedin.com/company/mirgroup-bd/">*/}
                                    {/*<img src={linkedin}/>*/}
                                        {linkedinIcon('#304550')}
                                    </a>
                                </span>
                                <span className="icon-single">
                                    <a target="_blank" href="https://twitter.com/MirGroupBD">
                                    {/*<a target="_blank" href="https://www.linkedin.com/company/mirgroup-bd/">*/}
                                    {/*<img src={linkedin}/>*/}
                                        {twitterIcon('#304550')}
                                    </a>
                                </span>
                                <span className="icon-single">
                                    <a target="_blank" href="https://www.youtube.com/channel/UCO9XQE0LP07eGhIrRfSAsCQ">
                                    {/*<a target="_blank" href="https://www.youtube.com/channel/UCNn9WtWDKALrvxy-PGrgYMA">*/}
                                    {/*<img src={youtube}/>*/}
                                        {youtubeIcon('#304550')}
                                    </a>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="floating-social">
                <div className="icon-container">
                                <span className="icon-single">
                                    <a target="_blank" href="https://www.facebook.com/mirgroupfb">
                                        {fbIcon('#ffffff')}
                                    </a>
                                </span>
                    <span className="icon-single">
                                    <a target="_blank" href="https://www.linkedin.com/company/mirgroupbangladesh/?viewAsMember=true">
                                    {/*<a target="_blank" href="https://www.linkedin.com/company/mirgroup-bd/">*/}
                                    {/*<img src={linkedin}/>*/}
                                        {linkedinIcon('#ffffff')}
                                    </a>
                                </span>
                    <span className="icon-single">
                                    <a target="_blank" href="https://twitter.com/MirGroupBD">
                                    {/*<a target="_blank" href="https://www.linkedin.com/company/mirgroup-bd/">*/}
                                    {/*<img src={linkedin}/>*/}
                                        {twitterIcon('#ffffff')}
                                    </a>
                                </span>
                    <span className="icon-single">
                                    <a target="_blank" href="https://www.youtube.com/channel/UCO9XQE0LP07eGhIrRfSAsCQ">
                                    {/*<a target="_blank" href="https://www.youtube.com/channel/UCNn9WtWDKALrvxy-PGrgYMA">*/}
                                    {/*<img src={youtube}/>*/}
                                        {youtubeIcon('#ffffff')}
                                    </a>
                                </span>
                </div>
            </div>
        </StyledFooter>
    )
};


const StyledFooter = styled.div`
  position: relative;

  .footer__top {
    text-align: center;
    margin-top: 20px;
  }

  .footer-top-container {
    height: 40px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      height: 1px;
      margin: auto;
      left: 0;
      width: 100%;
      background-color: #E6E6E6;
    }

    img {
      background-color: white;
      padding: 0 30px;
      position: relative;
      z-index: 3;
    }
  }

  .footer-bottom-container {
    background-color: #F3F3F3;
    height: 100px;
    display: flex;
    align-items: center;
  }

  .footer__menu {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 80px;

    &__single {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      flex-direction: column;
      padding: 0 15px;

      h4 {
        margin: 0 0 40px 0;
        font-size: 24px;
        font-weight: bold;
        line-height: 36px;
        text-transform: capitalize;
      }

      ul {
        li {
          a {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            display: block;
            margin-bottom: 15px;
            text-transform: capitalize;

            &:hover {
              text-decoration: underline;
            }
          }

          &:nth-last-of-type(1) a {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .footer__bottom {
    padding: 17px 15px;

    p {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 3px;

      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }

      a {
        &:hover {
          text-decoration: underline !important;
        }
      }
    }

  }

  .footer__menu-mobile {
    display: flex;
    margin-bottom: 60px;
  }

  .footer__menu-mobile__single { {
    padding: 0 15px;
    display: flex;
    flex: 1;

    ul {
      li {
        a {
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
          display: block;
          margin-bottom: 20px;
          text-transform: capitalize;
        }

        &:nth-last-of-type(1) a {
          margin-bottom: 0;
        }
      }
    }

    &.division {
      ul {
        li {
          a {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 8px;
            display: block;
            line-height: 24px;
          }

          &:nth-of-type(1) a {
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  }

  .footer__menu-mobile {
    width: 100%;
  }

  .footer-icon {
    float: right;

    .icon-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 10px;

      .icon-single a {
        height: 30px;
        width: 30px;
        border: 1px solid #304550;
        margin: 0 10px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: all .2s ease-in;
        
        svg path{
          fill:rgb(48, 69, 80);
        }

        &:hover {
          background-color: ${darkGreen};

          svg {
            path {
              fill: #ffffff;
            }
          }
        }

        img {
          height: 13px;
        }
      }
    }
  }

  .floating-social{
    position: fixed;
    z-index: 9;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    .icon-container{
      display: flex;
      flex-direction: column;
      .icon-single{
        width: 50px;
        height: 50px;
        a{
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${darkGreen};
          &:hover{
            background-color: #fff;
            svg{
              path{
                fill: ${darkGreen};
              }
            }
          }
        }
        
        &:not(:last-child){
          border-bottom: 1px solid #ffffff;
        }
      }
    }
  }

  @media (min-width: 767px) {
    .footer__menu-mobile {
      display: none;
    }
  }
  @media (max-width: 767px) {
    .desktop-menu {
      display: none;
    }
  }

  @media (max-width: 1030px) {
    .footer__menu {
      flex-wrap: wrap;
      width: 100%;
    }

    .footer__menu__single {
      min-width: 25%;
      max-width: 25%;
      margin-bottom: 40px;
    }
  }
`;

function mapDispatchToProps(dispatch) {
    return {
        getFooterOffset: data => dispatch(setFooterOffset(data)),
        setSectionId: data => dispatch(setSectionId(data))
    }
}

export default connect(null, mapDispatchToProps)(Footer);







