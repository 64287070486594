import React, { Component } from 'react';
import InnerBanner from '../../components/InnerBanner';
import AboutUs from './components/AboutUs';
import MissionVision from './components/MissingVision';
import Awards from './components/Awards';
import History from './components/History';
import CeoMessage from './components/CeoMessage';
import Achievements from './components/Achievements';
import Menu from '../../components/Menu';
import {ApiParams} from "../../constants/apiParams";
import {fetchAboutMain, fetchAboutHistory} from "../../redux/actions/about";
import {connect} from "react-redux";
import {baseUrl} from "../../components/BaseUrl";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import NumberRows from '../../components/NumberRows'
import AchievementSlider from "./components/AchievementSlider";
import {Loading} from "../../components/Loading";
import {Helmet} from "react-helmet";


class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    let mainParam = {
      [ApiParams.page_type]: 'about-us'
    }
    this.props.getAboutMain(mainParam);

    if(this.props.sectionId) {
      let elem = document.getElementById(this.props.sectionId)
      if (elem) elem.scrollIntoView({behavior: "smooth", block: "start"})
    }
  }

  render() {
    let { aboutMain } = this.props

    let description = aboutMain ?
      aboutMain.page_data ? aboutMain.page_data.description : ''
      : ''

    let bannerImagePath = aboutMain ?
      aboutMain.images ?
        aboutMain.images.list ?
          aboutMain.images.list[0] ?
            aboutMain.images.list[0].path + aboutMain.images.list[0].img_name : null
        : null
      : null
      : null;

    let banner = bannerImagePath ? baseUrl + bannerImagePath : ''

    let posts = aboutMain ?
      aboutMain.posts ?
        aboutMain.posts.list ?
          aboutMain.posts.list.length ? aboutMain.posts.list : []
          : []
        : []
      : [];

    return (
      <>
          <Helmet>
              <title>About Us | Mir Group</title>
          </Helmet>
          <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
              <Menu />
              {this.props.aboutMainLoading && <Loading/>}
              <InnerBanner
                  title={'about us'}
                  // subTitle={'about us'}
                  // text={bannerSub}
                  image={banner}
              />
              <AboutUs description={description} />
              <NumberRows />
              <MissionVision posts={posts} />
              {/*<AchievementSlider posts={posts} />*/}
              {/*<History historyMain={aboutHistory} />*/}
              <CeoMessage posts={posts} />
              <Awards posts={posts} />
              <Achievements posts={posts}/>
          </motion.div>
      </>
    )
  }
};

function mapStateToProps(state) {
  return {
      aboutMain: state.aboutReducer.aboutMain,
      aboutMainLoading: state.aboutReducer.aboutMainLoading,
      aboutHistory: state.aboutReducer.aboutHistory,
      sectionId: state.menuReducer.sectionId
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getAboutMain: params => dispatch(fetchAboutMain(params)),
    getAboutHistory: params => dispatch(fetchAboutHistory(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(About);
