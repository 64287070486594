import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {hover, darkGreen} from '../globalStyleVars';
import mainLogo from '../assets/images/logo-mir.svg';
import whiteLogo from '../assets/images/logo-white.svg';
import hambuerger from '../assets/images/menu-hambuerger.svg';
import hambuergerWhite from '../assets/images/close.svg';
import {Link, useLocation} from 'react-router-dom';
import menuBg from '../assets/images/menuBg.png';
import $ from 'jquery';
import {setSectionId} from "../redux/actions/menu";
import {connect} from 'react-redux'

const Menu = ({logo, color, img, setSectionId}) => {
    let [menuItems, setMenuItems] = useState(false);
    let [newClass, setNewClass] = useState('');
    let [mobileSlide, setMobileSlide] = useState('');

    let handleMenuToggle = (e) => {
        e.stopPropagation();
        setMenuItems(!menuItems);
    };

    let handleClose = () => {
        setMenuItems(false)
    };

    let {pathname} = useLocation();

    useEffect(() => {
        window.addEventListener('scroll', function () {
            if (window.pageYOffset > 100) {
                setNewClass('menu-fixed')
            } else {
                setNewClass('')
            }
        });

        // menu click on mobile
        $('.mobile-menu .menuItems__top__single').each(function () {
            let $this = $(this);
            if ($this.find('ul').length > 0) {
                $this.addClass('hasChild')
            }
        });

        $('.hasChild').on('click', function () {
            var $this_ul = $(this).find('ul'),
                $this = $(this);
            $('.hasChild ul').not($this_ul).slideUp();
            $('.hasChild').not($this).removeClass('active');
            $this_ul.slideToggle();
            $this.toggleClass('active');
        });

        $('.mobile-menu .menuItems__top__single li a').click(function () {
            $('.menu-items').removeClass('true');
            $('.menu-bar').removeClass('true');

        })

        if ($(window).width() > 768) {
            $('.has-sub').click(function () {
                $(this).toggleClass('slide-sub');
                $(this).next('.sub-menu').toggleClass('slide-sub')
            })
        }
    }, []);

    return (
        <div>
            <StyledMenu className={`menu-bar ${newClass} ${menuItems}`} color={color}>
                <Container>
                    <Row>
                        {/*<Col className="menu-bar__logo"><a href="/"><img src={logo || mainLogo} alt=""/></a> </Col>*/}
                        <Col xs={9} className="menu-bar__menu-lists">
                            <ul onClick={handleClose}>

                                <li onClick={handleMenuToggle}>
                                    <a href="javascript:void(0)">
                                        <img src={img || hambuerger} alt=""/>
                                        <img src={hambuergerWhite} alt=""/>
                                    </a>
                                </li>


                            </ul>
                        </Col>
                        <Col xs={3} className="menu-bar__logo">
                            <Link to="/">
                                <img src={newClass.length !== 0 ? whiteLogo : mainLogo} alt=""/>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </StyledMenu>

            {/*toggle menu */}

            <StyleToggleMenu className={`menu-items ${menuItems}`}>

                <img src={menuBg} alt=""/>
                <Container>
                    <div className="desktop-menu">
                        <div className="menuItems__top">  {/*onClick={handleMenuToggle}*/}


                            <div className="menuItems__top__single">
                                <Link to={'/'}>Home</Link>
                                <Link to={'/about'}>About</Link>
                                <Link to={'#'} className="has-sub">Group Concerns</Link>

                                <div className="sub-menu">
                                    <ul>
                                        <li>
                                            <Link to={'/concern-detail/mir-akhter-hossain-limited'}>Mir Akhter Hossain
                                                Limited</Link>
                                        </li>
                                        <li>
                                            <Link to={'/concern-detail/mir-cement-limited'}>Mir Cement Limited</Link>
                                        </li>
                                        <li>
                                            <Link to={'/concern-detail/mir-concrete-products-limited'}>Mir Concrete
                                                Products Limited</Link>
                                            <ul>
                                                <li>
                                                    <Link to={'/concern-detail/mir-concrete-products-limited'}>Mir
                                                        Ready-Mix Concrete</Link>
                                                </li>
                                                <li>
                                                    <Link to={'/concern-detail/mir-concrete-products-limited'}>Mir
                                                        Concrete Block</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li><Link to={'/concern-detail/mir-real-estate-limited'}>Mir Real Estate
                                            Limited</Link></li>
                                        <li><Link to={'/concern-detail/ergo-ventures-private-limited'}>Ergo Ventures
                                            Limited</Link></li>
                                        <li><Link to={'/concern-detail/sego-global-limited'}>Sego Global Limited</Link>
                                        </li>
                                    </ul>
                                </div>


                                <Link to={'/news'}>News And Events</Link>
                                <Link to={'/career'}>Career</Link>
                                <Link to={'/gallery'}>Gallery</Link>
                                <Link to={'/contact'}>Contact</Link>
                            </div>
                            <div className="right__bottom">
                                <p>
                                    House: B 147, Road 22, Mohakhali DOHS, Dhaka 1206
                                </p>
                                <p>
                                    Phone: +8802222289335-9
                                </p>
                                <p>
                                    Email: info@mirgroupbd.com
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="mobile-menu">
                        <div className="menuItems__top">
                            <div className={`menuItems__top__single`}>
                                <Link to={'/'}>Home</Link>
                            </div>
                            <div className={`menuItems__top__single`}>
                                <Link to={'/about'}>About</Link>
                            </div>
                            <div className={`menuItems__top__single`}>
                                <Link to={'/concerns'}>Group Concerns</Link>
                                <ul className="has-sub">
                                    <li>
                                        <Link to={'/concern-detail/mir-akhter-hossain-limited'}>Mir Akhter Hossain
                                            Limited</Link>
                                    </li>
                                    <li>
                                        <Link to={'/concern-detail/mir-cement-limited'}>Mir Cement Limited</Link>
                                    </li>
                                    <li>
                                        <Link to={'/concern-detail/mir-concrete-products-limited'}>Mir Concrete Products
                                            Limited</Link>
                                        <ul>
                                            <li>
                                                <Link to={'/concern-detail/mir-concrete-products-limited'}>Mir Ready-Mix
                                                    Concrete</Link>
                                            </li>
                                            <li>
                                                <Link to={'/concern-detail/mir-concrete-products-limited'}>Mir Concrete
                                                    Block</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li><Link to={'/concern-detail/mir-real-estate-limited'}>Mir Real Estate
                                        Limited</Link></li>
                                    <li><Link to={'/concern-detail/ergo-ventures-private-limited'}>Ergo Ventures
                                        Limited</Link></li>
                                    <li><Link to={'/concern-detail/sego-global-limited'}>Sego Global Limited</Link></li>
                                </ul>
                            </div>
                            <div className={`menuItems__top__single`}>
                                <Link to={'/news'}>News and Events</Link>
                            </div>
                            <div className={`menuItems__top__single`}>
                                <Link to={'/career'}>Career</Link>
                            </div>
                            <div className={`menuItems__top__single`}>
                                <Link to={'/gallery'}>Gallery</Link>
                            </div>
                            <div className={`menuItems__top__single`}>
                                <Link to={'/contact'}>Contact</Link>
                            </div>

                        </div>
                    </div>
                </Container>
            </StyleToggleMenu>


        </div>
    )
};
const StyledMenu = styled.div`
  position: fixed;
  z-index: 9;
  width: 100%;
  top: 0;
  height: 90px;
  align-items: center;
  display: flex;
  transition: background-color .3s ease;

  .row {
    align-items: center;
  }

  &.menu-fixed {
    background-color: #008C44;

    .menu-bar__menu-lists {
      ul {
        li {
          a {
            &:hover, &.active {
              color: ${darkGreen};
            }
          }

        }
      }
    }
  }

  &.true {
    background-color: transparent;
  }

  .menu-bar__logo {
    img {
      height: 54px;
      object-fit: contain;
      position: absolute;
      right: 0;
      top: -20px;
    }
  }

  .menu-bar__menu-lists {
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;

      li {
        a {
          font-size: 18px;
          font-weight: bold;
          line-height: 27px;
          text-transform: capitalize;
          color: ${props => props.color || '#ffffff'};
          transition: color .3s ease;

          &:hover, &.active {
            color: ${hover};
          }
        }

        &:nth-last-of-type(1) a {
          transition: opacity .4s ease;
          transition-delay: 0s;

          &:hover {
            opacity: .4 !important;
          }
        }

        &:nth-last-of-type(1) a {
          img:nth-last-of-type(1) {
            display: none;
            height: 22px;
          }
        }
      }
    }
  }

  &.true {
    li:not(:last-child) a {
      opacity: 0 !important;
      visibility: hidden !important;
    }

    li:nth-last-of-type(1) a img {
      &:nth-of-type(1) {
        display: none;
      }

      &:nth-of-type(2) {
        display: block !important;
      }
    }
  }

  @media (max-width: 991px) {
    .menu-bar__menu-lists {
      ul {
        justify-content: flex-end;

        li:not(:last-child) {
          display: none;
        }
      }
    }
  }

  @media (max-width: 767px) {
    height: 65px;
    .menu-bar__logo {
      text-align: right;

      img {
        height: 45px;
        position: relative;
        top: 0;
      }
    }

    .menu-bar__menu-lists {
      ul {
        justify-content: flex-start;
      }
    }
  }
`;

const StyleToggleMenu = styled.div`
  position: fixed;
  z-index: 8;
  height: 100vh;
  width: 100%;
  background-color: #306939;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: all .6s ease;

  &:after {
    position: absolute;
    height: 100vh;
    width: 100%;
    //background-color: rgba(48,105,57,0.1);
    content: '';
    left: 0;
    top: 0;
  }

  > img {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    object-fit: cover;
  }

  &.true {
    opacity: 1;
    visibility: visible;

  }

  .menuItems__top {
    height: calc(100vh - 130px);
    display: flex;
    padding: 80px;
    position: relative;
    //background:  #0366C2;
    margin-top: 90px;
    max-height: 600px;


    .right__bottom {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 20%;
      z-index: 2;

      p {
        text-align: right;
        color: white;
        font-size: 14px;
      }
    }

    &__bg {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      height: 100%;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(48, 105, 57, 0.9);
        right: 0;
        bottom: 0;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    &__single {
      position: relative;
      z-index: 3;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      height: max-content;
      flex-direction: column;
      padding-right: 8px;

      > a {
        font-size: 25px;
        font-weight: 500;
        color: #ffffff;
        text-transform: capitalize;
        line-height: 30px;
        margin-bottom: 26px;
        transition: letter-spacing .6s ease;
        width: fit-content;

        &:hover {
          letter-spacing: 2px;
        }
      }

      .sub-menu {
        display: none;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        

        > ul {
          text-align: left;
          >li{
            > ul{
              padding-left: 30px;
              margin-bottom: 30px;
            }
          }

          li {
            a {
              display: block;
              font-size: 16px;
              text-transform: capitalize;
              color: #ffffff;
              margin-bottom: 18px;
              transition: padding .4s ease;
              white-space: nowrap;

              &:hover {
                padding-left: 10px;
              }
            }

            &:nth-last-of-type(1) a {
              margin-bottom: 0;
            }
          }
        }
      }

      .concern-sub:hover .sub-menu {
        display: block;
      }
    }
  }

  @media (min-width: 1440px) {
    @media (min-width: 800px) {
      display: flex;
      align-items: center;
      .menuItems__top {
        padding: 100px;
        margin-top: 0;
      }
    }

  }


  @media (min-width: 768px) {
    .mobile-menu {
      display: none;

    }
  }

  @media (max-width: 767px) {
    height: 100vh;
    padding-top: 30px;
    .menuItems__top {
      height: auto;
      display: block;
      padding: 0px 0px 0 0px;
      position: relative;
      min-height: 100vh;
      background-color: transparent;

      &__bg {
        display: none !important;
      }

      &__single {
        display: block;
        width: 100%;

        > a {
          font-size: 15px;
          display: block;
          margin-bottom: 15px
        }

        ul {
          display: none;
        }
      }
    }

    .Footer__social {
      margin-top: 50px;
    }

    .desktop-menu {
      display: none;
    }

    .mobile-menu {
      .menuItems__top__single.active > a {
        color: #ffffff;
        opacity: .7;
      }

      .has-sub a {
        color: #ffffff !important;
      }
    }
  }

  .menuItems__top__single {
    position: relative;

    &:after {
      content: '';
      background-image: url(${hambuergerWhite});
      background-size: 13px;
      position: absolute;
      right: -17px;
      height: 50px;
      width: 50px;
      top: -12px;
      background-repeat: no-repeat;
      background-position: center;
      transform: rotate(45deg);
      margin: auto;
      z-index: 3;
      display: none;
      transition: all .4s ease;
    }
    
    ul {
      &.has-sub{
        margin-bottom: 30px;
        li{
          & > ul{
            padding-left: 30px;
          }
        }
      }
    }

    &.hasChild {
      &:after {
        display: block;
      }
    }

    &.active {
      &:after {
        transform: none;
      }

      > a {
        color: ${hover};

        ul li a {
          color: #d4d4d4 !important;
        }
      }
    }


  }

  .desktop-menu {
    .menuItems__top__single {
      max-width: fit-content;

      .has-sub {
        position: relative;

        &:after {
          content: '';
          height: 1px;
          width: 140px;
          background-color: #fff;
          left: calc(100% + 0px);
          z-index: 5;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          opacity: 0;
          visibility: hidden;
          transition: all .6s ease;
        }

        &.slide-sub:after {
          left: calc(100% + 40px);
          opacity: 1;
          visibility: visible;
        }
      }

      .sub-menu {
        position: absolute;
        z-index: 1;
        top: 0;
        left: calc(100% + 0px);
        display: block;
        opacity: 0;
        visibility: hidden;
        transition: all .5s ease;

        &.slide-sub {
          left: calc(100% + 175px);
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

`;

function mapDispatchToProps(dispatch) {
    return {
        setSectionId: data => dispatch(setSectionId(data))
    }
}

export default connect(null, mapDispatchToProps)(Menu);
