import React, {useState} from 'react';
import {hover, darkGreen} from '../globalStyleVars';
import {Modal} from "react-bootstrap";
import styled from 'styled-components';
import {Container, Table, Col, Row} from "react-bootstrap";
import Button from './Button';
import ReactHtmlParser from 'react-html-parser'


const Popup = ({show, handleClose, post, title}) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title><h2>{title}</h2></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {ReactHtmlParser(post)}
            </Modal.Body>
        </Modal>

    )
};

export default Popup;
