import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {hover} from "../../../globalStyleVars";
import Title from '../../../components/Title';
import {ApiParams} from "../../../constants/apiParams";
import {connect} from 'react-redux'
import {fetchCareerListData} from "../../../redux/actions/career";
import Button from "../../../components/Button";
import Text from "../../../components/Text";
import ReactHtmlParser from "react-html-parser";
import arrowButton from '../../../assets/images/btn-arrow-green.svg'

const options = [
  {value: 'Washing', label: 'Chocolate'},
  {value: 'Knitting', label: 'Strawberry'},
  {value: 'Printing', label: 'Vanilla'}
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
    backgroundColor: state.isSelected ? '#1EC3AA' : '#FFF',
    margin: 0
  }),
  menu: (provided, state) => ({
    ...provided,
    color: 'rgba(0,0,0,0.5)',
    backgroundColor: state.isSelected ? '#000' : '#FFF',
    margin: 0
  }),
  menuList: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#000' : '#FFF',
    borderRadius: 0,
  }),

};

class Jobs extends Component {
  render() {
    let { careerPage } = this.props

    let careerPosts = careerPage ? careerPage.posts ? careerPage.posts.list : [] : []

    return (
      <div>
        <StyledJobFilter className="job-filter">
          <Container>
            <Title
              text={'HR Philosophy'}
              fontSize={28}
              color={'#304550'}
              margin={'0 0 20px 0'}
            />
            <Text
              margin={'0 0 20px 0'}
              fontWeight={'400'}
              lineHeight={'27'}
              fontSize={'16'}
              color={'#304550'}
            >
              {careerPage ?
              careerPage.page_data ?
                ReactHtmlParser(careerPage.page_data.description) : ''
              : ''
              }
            </Text>
          </Container>
        </StyledJobFilter>
        <StyledJobItems>
          <Container>
            <Row>
              <Col md={12}>
                <Title
                  fontSize={'30'}
                  lineHeight={'45'}
                  fontWeight={'500'}
                  margin={'0 0 30px 0'}
                  text={'Available Jobs'}
                  color={'#304550'}
                />
                <div className="job__list">
                  <Row>
                    {careerPosts.length ? careerPosts.map(post => {
                      return (
                        <Col md={6} xs={12} sm={12}>
                          <div key={post.data.id} className="job__list__single">
                            <div className="job__list__single__desc">
                              <h4>{post.data.title}</h4>
                              <ul>
                                <li>
                                  <span>Employment Status:</span> <span>{post.data["Extra description one"]}</span>
                                </li>
                                <li>
                                  <span>Exp. Requirements:</span> <span>{post.data["Extra description two"]}</span>
                                </li>
                                <li>
                                  <span>Salary:</span> <span>{post.data["Extra description three"]}</span>
                                </li>
                              </ul>

                              <Button
                                link={`/career/apply/${post.data.slug}`}
                                text={'Apply Now'}
                                margin={'60px 0 0 0'}
                                postProp={post.data}
                                img={arrowButton}
                              />
                            </div>
                          </div>
                        </Col>
                      )
                    }) : ''}
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </StyledJobItems>
      </div>
    )
  }
};


// filter
const StyledJobFilter = styled.div`
  background-color: #F3FCFB;
  padding: 50px 100px;
  .container{
    overflow: visible;
  }

  .job-content{
    background-color: #f9f9f9;
    height: auto;
  }
  
  .job__menu{
    display: flex;
    justify-content: space-between;
    li{

      a{
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
        color: rgba(0,0,0,0.15);
        display: block;
        text-transform: capitalize;
        transition:color .3s ease;
        &:hover{
          color: #000;
        }
      }
      &.active a{
        color: #000;
      }
    }
  }


  @media(max-width:767px){
    padding: 10px 0 25px 0;
    .job__menu{
      display: none;
    }
    &.division-filter{
      .sub-title{
        margin-bottom: 10px;
      }
      .container{
        overflow: visible !important;
      }
    }
  }

  .css-g1d714-ValueContainer{
    padding-left: 0;
  }
  .css-yk16xz-control,.css-1pahdxg-control{
    padding-left: 0;
    border:none;
    border-bottom:1px solid #D9D9D9 !important;
    background-color: transparent !important;
  }
  .css-tlfecz-indicatorContainer{
    padding-right: 0 !important;
    margin-right: 0 !important;
  }
  .css-1uccc91-singleValue{
    font-size: 20px;
    font-weight: bold;
  }

  @media(min-width:768px){
    .mobile-filter{
      display: none;
    }
  }


`;

//items
const StyledJobItems = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  .job__list{
    &__single{
      background-color: #F9F9F9;
      padding: 35px 40px 35px 70px;
      box-shadow: 0 0 10px rgba(0,0,0,0.06);
      display: inline-flex;
      width: 100%;
      margin-bottom: 20px;
      align-items: center;
      &:nth-last-of-type(1){
        margin-bottom: 0;
      }
      &__date{
        padding-right: 45px;

        p{
          font-size: 14px;
          font-weight: 300;
          height: 21px;
          color: #58585A;
          margin: 0;
        }
        h4{
          font-size: 48px;
          color: ${hover};
          margin: 0;
          line-height: 58px;
          font-weight: bold;
        }


      }
      &__desc{
        padding-left: 30px;
        width: 100%;
        h4{
          font-size: 20px;
          line-height: 34px;
          margin: 0 0 20px 0;
          font-weight: 500;
          color: #304550;
        }
        li{
          font-size: 14px;
          line-height: 21px;
          font-weight: 300;
          color: #58585a;
          margin-bottom: 5px;
          width: 100%;
          display: flex;
          span{
            display: inline-flex;
            color: #304550;
            &:nth-of-type(1){
              flex:1;

            }
            &:nth-of-type(2){
              flex:3;

            }
          }
        }
        a{
          margin-top: 10px;
        }
      }
    }
  }


  .popup-on{
    p{
      margin: 20px 0 0 0 ;
      color: #1EC3AA;
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
      line-height: 21px;
      cursor: pointer;
      transition: letter-spacing .3s ease;
      img{
        margin-left: 10px;
      }
      &:hover{
        letter-spacing: 2px;
      }
    }
  }


  @media(max-width:992px){
    .job__list__single__desc li{
      justify-content: space-between;
      span{
        flex: 1 !important;
      }
    }
  }
  @media(max-width:767px){
    padding-top: 80px !important;
    .job__list__single{
      padding: 30px 10px;
      .job__list__single__desc{
        padding-left: 15px;
        h4{
          font-size: 20px;
        }
        li{
          font-size: 14px;
          font-weight: 400;
          span{
            &:nth-of-type(1){
              flex: 2;
            }
            &:nth-of-type(2){
              flex: 1;
              padding-left: 20px;
            }
          }
        }
      }
    }
    .job__list__single__date{
      padding-right: 20px;
      h4{
        font-size: 36px;
        line-height: 40px;
      }
    }
  }
  @media (max-width: 410px){
    .job__list__single .job__list__single__desc li {
      font-size: 11px;
      line-height: 18px;
    }
  }

`;

export default Jobs;
