import Index from './container/home/';
import React, {useEffect} from 'react';
import Division from './container/division/';
import DivisionDetail from './container/division/division-detail';
import About from './container/about/';
import Career from './container/career/';
import sustainabilityDetail from './container/home/general-detail';
import CareerApply from './container/career/apply';
import Media from './container/media-news/';
import MediaDetails from './container/media-news/details';
import Services from './container/services/';
import StyledGlobal from './globalStyle';
import Footer from './components/Footer';
import Contact from './container/contact';
import NewsEvents from './container/news'
import CSR from './container/csr'
import Error from './container/error';
import {Route, Switch,useLocation} from 'react-router-dom';
import {AnimatePresence } from 'framer-motion';

function App() {
  const location = useLocation()
  return (
    <React.Fragment>
      <StyledGlobal/>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname} >
          <Route exact path="/" component={Index}/>
          <Route exact path="/concerns" component={Division}/>
          <Route exact path="/concern-detail/:slug" component={DivisionDetail}/>
          <Route exact path="/about" component={About}/>
          <Route exact path="/career" component={Career}/>
          <Route exact path="/gallery" component={Media}/>
          <Route exact path="/sustainability/details/" component={sustainabilityDetail}/>
          <Route exact path="/services" component={Services}/>
          <Route exact path="/contact" component={Contact}/>
          <Route exact path="/news" component={NewsEvents}/>
          <Route exact path="/career/apply/:title" component={CareerApply}/>
          <Route exact path="/media/details/:slug/:post_slug" component={MediaDetails}/>
          <Route component={Error}/>
        </Switch>
      </AnimatePresence>
      <Footer/>
    </React.Fragment>
  );
}


export default App;
