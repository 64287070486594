import React, { Component } from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {hover, darkGreen} from '../../../globalStyleVars';
import Swiper from 'react-id-swiper';
import SubTitle from '../../../components/SubTitle';
import Links from '../../../components/Link';
import Button from '../../../components/Button';
import Title from '../../../components/Title';
import {baseUrl} from '../../../components/BaseUrl';
import {connect} from 'react-redux';
import {fetchHomeNewsData} from "../../../redux/actions/home";
import Lines from '../../../components/VerticalLine';
import {Link} from 'react-router-dom'
// css
import "swiper/swiper.scss";

// img
import arrowImg from '../../../assets/images/btn-arrow-green.svg'
import RightIcon from "../../../assets/images/navRightBlack.svg";
import LeftIcon from "../../../assets/images/navLeftBlack.svg";
import { ApiParams } from "../../../constants/apiParams";
import VisibilitySensor from "react-visibility-sensor";
import moment from "moment";

class NewsEvents extends Component {
  componentDidMount() {
    let params = {
      [ApiParams.page_type]: 'home-news-and-events'
    }
    this.props.getHomeNewsData(params)
  }

  render() {
    let { newsEvents } = this.props

    const newsEventData = newsEvents ? newsEvents.posts ? newsEvents.posts.list : [] : [];

    let len = newsEventData.length

    const params = {
      slidesPerView: 1,
      spaceBetween: 30,
      speed: 1500,
      autoplay: {
        delay: 5500,
        disableOnInteraction: true,
      },
      breakpoints:{
        769: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
      // navigation: {
      //     nextEl: '.swiper-button-next',
      //     prevEl: '.swiper-button-prev'
      // },
    };

    let type = newsEvents ? newsEvents.page_data ? newsEvents.page_data.slug : '' : ''

    return (
      <StyledNewsEvents className="pt-110 pb-110">
        <Container>
          <Title
            color={'#304550'}
            margin={'20px 0 40px 0'}
            text={'News & events'}
            fontSize={36}
          />
          <div className="NewsEvents-slider__bottom">
            <Button
              link={'/news'}
              text={'see all news'}
              color={`#304550`}
              img={arrowImg}
              borderColor={`#304550`}
            />
          </div>
        </Container>

        <Container className="news-events-slider">
          {len && len === newsEventData.length ? (
            <Swiper  {...params}>
              {newsEventData.map(data => (
                <div key={data.data.id} className="news-events-slider__single">
                  <div className="news-events-slider__single__img">
                    <Link to={`/media/details/${type}/${data.data.slug}`}></Link>
                    {data.images ? data.images.map(img => (
                      <img key={img.id} src={baseUrl + img.path + img.img_name} alt=""/>
                    )) : ''}
                  </div>
                  <div className="news-events-slider__single__content">
                    <Link to={`/media/details/${type}/${data.data.slug}`}></Link>
                    <Title
                      text={moment(data.data.updated_at).format('DD.MM.YYYY')}
                      fontSize={'14'}
                      fontWeight={100}
                      lineHeight={'15'}
                      margin={'20px 0 10px 0'}
                      color={'#324752'}
                    />
                    <Title
                      text={data.data.title}
                      fontSize={'20'}
                      fontWeight={'bold'}
                      lineHeight={'30'}
                      margin={'0 0 40px 0'}
                      color={'#324752'}
                    />
                  </div>
                </div>
              ))}

            </Swiper>
          ) : null}

        </Container>
      </StyledNewsEvents>
    )
  }
};


const StyledNewsEvents = styled.div`
  background-color: rgba(0,0,0,0.03) ;
  position: relative;
  .news-events-slider{
    .swiper-wrapper{
      margin-bottom: 0;
    }
    &__single{

      &__content{
        h4{
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 500;
          line-height: 21px;
          margin: 0 0 20px 0;
          color: rgba(9,32,59,0.50);
        }
        p{
          font-size: 20px;
          font-weight: bold;
          line-height: 30px;
          margin-bottom: 40px;
        }
        h2{
          width: 80%;
          &:last-child{
            margin-bottom: 0;
          }
        }

      }

      &__img{
        padding-top: 67.57%;
        position: relative;
        overflow: hidden;
        margin-top: 40px;
        z-index: 3;
        a{
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          z-index: 3;
        }
        img{
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          transform: scale(1.01);
          top: 0;
          left: 0;
          transition:all 2s ease;

        }
        &:hover{
          img{
            transform: scale(1.05);
            filter:contrast(140%);
          }
        }
      }
    }

    &__bottom{

      margin-top: -50px;
    }
  }

  .NewsEvents-slider__bottom{
    margin-top: -80px;
    position: relative;
    float: right;
    z-index: 3;
  }

  .swiper-button-next{
    position: absolute;
    right: 0px;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    background-image: url(${RightIcon});
    background-position: center;
    background-size: contain;
    top: auto;
    left: auto;
    &:focus{
      outline:none;
    }
  }
  .swiper-button-prev{
    position: absolute;
    right: 60px;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    top: auto;
    left: auto;
    background-image: url(${LeftIcon});
    background-size: contain;
    &:focus{
      outline:none;
    }
  }
  .swiper-wrapper{
    margin-bottom: 150px;
  }
  a{
    .outer-button{
      img{
        stroke: #000;
        fill: #000;
        filter: brightness(100) invert(3);
      }
    }
    &:hover{
      .outer-button{
        img{
          stroke: #000;
          fill: #000;
          filter: brightness(1) invert(0);
        }
      }
    }
  }
  
  
  @media(max-width:767px){
    .sub-title:nth-of-type(1){
      margin-bottom: 58px;
    }
    .title{
      width: 100% !important;
    }

    .swiper-wrapper {
      margin-bottom: 110px;
    }

    .NewsEvents-slider__bottom{
      margin-top: -60px;
    }
  }
`;

function mapStateToProps(state) {
  return {
    newsEvents: state.homeReducer.newsEvents
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getHomeNewsData: (params) => dispatch(fetchHomeNewsData(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsEvents);

