import React, {Component, useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {hover, darkGreen} from '../../../globalStyleVars';
import Swiper from 'react-id-swiper';
import SubTitle from '../../../components/SubTitle';
import Text from '../../../components/Text';
import {ApiParams} from "../../../constants/apiParams";
import ReactHtmlParser from "react-html-parser";
import Title from "../../../components/Title";

// img
import bg from '../../../assets/images/dynamic/acheivement.jpg'
import RightIcon from "../../../assets/images/navRight.svg";
import LeftIcon from "../../../assets/images/navLeft.svg";

import {connect} from 'react-redux';
import {fetchHomeAchievementData} from "../../../redux/actions/home";
import VisibilitySensor from "react-visibility-sensor";

class AchievementSlider extends Component {
  render() {
    let {posts} = this.props
    const achievementsList = posts ? posts.filter(item => item.data.slug === 'achievement') : []

    let len = achievementsList.length
    const params = {
      slidesPerView: 1,
      spaceBetween: 30,
      speed: 1500,
      autoplay: {
        delay: 5500,
        disableOnInteraction: true,
      },
      breakpoints: {
        900: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
    };

    return (
      <StyledDivision className="pt-110 pb-110">
        {/*<img src={bg} alt=""/>*/}
        <Container className="business-title">
          <Title
            text={'achievement'}
            color={'#304550'}
            fontSize={40}
          />
        </Container>

        <Container className="achievements-slider">
          {len && len === achievementsList.length ? (
            <Swiper  {...params}>
              {achievementsList.map(data => (
                <div key={data.data.id} className="achievements-slider__single">
                  <VisibilitySensor
                    partialVisibility={{top: 0, bottom: 60}}
                  >
                    {({isVisible}) =>
                      <h4 className={`${isVisible ? 'anim-active' : ''} fade-up`}>
                        {data.data.title}
                      </h4>
                    }
                  </VisibilitySensor>

                  <Text
                    fontSize={'14'}
                    lineHeight={'21'}
                    color={'#304550'}
                  >
                    {ReactHtmlParser(data.data.description)}
                  </Text>
                </div>
              ))}
            </Swiper>
          ) : null}
        </Container>
      </StyledDivision>
    )
  }
};

const StyledDivision = styled.div`
  position: relative;
  background-color: #EFFDF8;
  >img{
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    top: 0;
    bottom: 0;
  }
  .container{
    position: relative;
    z-index: 2;
  }

  .achievements-slider{
    margin-top: 100px;
    .swiper-container {
      padding-left: 60px;
      padding-right: 60px;
      position: relative;
      
      &:before {
        z-index: 2;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 60px;
        background-color: #EFFDF8;
      }

      &:after {
        content: '';
        z-index: 2;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 60px;
        background-color: #EFFDF8;
      }
    }
    .swiper-wrapper{
      margin-bottom: 0px;
    }
    &__single{
      h4{
        margin-top: 0;
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;
        text-transform: capitalize;
        margin-bottom: 30px;
        color: #304550;
      }

      a{
        margin-top: 30px;
        font-size: 16px;
        line-height: 18px;
        color: #ffffff;
        text-decoration: underline;
        display: block;
        transition:all .6s ease;
        position: absolute;
        bottom: -45px;
        &:hover{
          color: ${hover};
          letter-spacing: 2px;
        }
      }
    }
  }

  .swiper-button-next{
    position: absolute;
    right: 0px;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    top: 0;
    height: 50px;
    width: 50px;
    background-image: url(${RightIcon});
    background-position: center;
    background-size: contain;
    &:focus{
      outline:none;
    }
  }
  .swiper-button-prev{
    position: absolute;
    right: auto;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    background-image: url(${LeftIcon});
    background-size: contain;
    left: 0;
    top: 0;
    &:focus{
      outline:none;
    }
  }
  .swiper-slide{
    height: auto;
    margin-bottom: 30px;
  }
  @media(max-width:767px){
    .achievements-slider {
      margin-top: 50px;
    }

    .swiper-button-prev{
      left: 0;
      right: auto;
    }

    .swiper-button-next{
      //left: 70px;
      //right: auto;
    }
  }
`;

export default AchievementSlider;
