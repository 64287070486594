import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Links from '../../../components/Link';
import Title from '../../../components/Title';
import Button from '../../../components/Button';
import {Link} from "react-router-dom";
import moment from 'moment'
import Lines from '../../../components/VerticalLine';
// css
import "swiper/swiper.scss";

// img
import n1 from '../../../assets/images/dynamic/n1.jpg';
import n2 from '../../../assets/images/dynamic/n1.jpg';
import n3 from '../../../assets/images/dynamic/n1.jpg';

import arrowImg from '../../../assets/images/btn-arrow-black.svg'
import {connect} from 'react-redux'
import {baseUrl} from "../../../components/BaseUrl";

const News = ({ slug, mediaPageList }) => {
  let mediaPage = mediaPageList ? mediaPageList.find(media => {
    if(media.page_data.slug === slug) return media
  }) : null

  let newsPost = mediaPage ? mediaPage.posts.list : []

  let isCsr = mediaPage ? mediaPage.page_data ? mediaPage.page_data.slug === 'csr' : null : null

  return (
    <StyledNews>
      {/*<Lines />*/}
      <Container className="news">
        <Row>
          {newsPost.length ? newsPost.map((post) => {
              let postImage = post.images ?
                post.images[0] ? baseUrl + post.images[0].path + post.images[0].img_name : ''
                : ''
              return (
                <Col key={post.data.id} md={4}>
                  <div  className="news__single">
                    <div className="news__single__img">
                      <Link to={`/media/details/${slug}/${post.data.slug}`}/>
                      <img src={postImage} alt=""/>
                    </div>
                    <div className="news__single__content">
                      <Title
                        text={moment(new Date(post.data["Date"])).format('DD.MM.YYYY')}
                        fontSize={'14'}
                        fontWeight={100}
                        lineHeight={'15'}
                        margin={'20px 0 10px 0'}
                        color={'#324752'}
                      />
                      <Title
                        text={post.data.title}
                        fontSize={'20'}
                        fontWeight={'bold'}
                        lineHeight={'30'}
                        margin={'0 0 40px 0'}
                        color={'#324752'}
                      />
                    </div>
                  </div>
                </Col>
              )
            }) : null
          }
        </Row>

        {/*<Row>*/}
        {/*    <Col className='text-center'><Button width={'158px'} text={'Load More'} /></Col>*/}
        {/*</Row>*/}
      </Container>
    </StyledNews>

  )
};


const StyledNews = styled.div`
   position: relative;
   .news{
       &__single{
       margin-bottom: 60px;
       position: relative;
       z-index: 3;
           &__content{
               h4{
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 500;
                line-height: 21px;
                margin: 0 0 20px 0;
                color: rgba(9,32,59,0.50);
              }
              p{
                font-size: 20px;
                font-weight: bold;
                line-height: 30px;
                margin-bottom: 40px;
              }
              h2{
                width: 80%;
              }
      
           }
           
            &__img{
                padding-top: 67.57%;
                position: relative;
                overflow: hidden;
                margin-top: 40px;
                a{
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  z-index: 3;
                }
                img{
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                  transform: scale(1.01);
                  top: 0;
                  left: 0;
                  transition:all 2s ease;
                  
                }
                &:hover{
                  img{
                    transform: scale(1.05);
                    filter:contrast(140%);
                  }
                }
           }
       }
      
    &__bottom{
     margin-top: -50px;
    }  
    
    .dc-btn{
      margin: auto;
      position: relative;
      z-index: 3;
      img{
        display: none;
      }
    }
   }
  
   @media(max-width:767px){
   .news__single__content{
    h4{
      margin-bottom:10px;
    }
    .title{
      width: 100%;
      margin-bottom: 20px;
    }
   }
   .news__single__img{
    margin-top: 20px;
   }
   .news__single{
    margin-bottom: 40px;
   
   }
   .col-md-4{
     &:nth-last-of-type(1) .news__single{
      margin-bottom: 40px;
    }
   }
   
   }
`;


function mapStateToProps(state) {
  return {
    mediaPageList: state.mediaReducer.mediaPageList
  }
}

export default connect(mapStateToProps)(News);

