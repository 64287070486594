import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import SubTitle from './SubTitle';
import Title from './Title';
import Text from './Text';
import goDown from '../assets/images/goDown.svg';
import MiniBar from "./MiniBar";
import PreloadImage from 'react-preload-image';
import arrowImg from "../assets/images/btn-arrow-new.svg";
import Button from "./Button";


const InnerBanner = ({ height, link, top, title, titleFontSize, subTitle,text, image, backgroundColor, isButton})=>{
  return(
    <StyledBanner
        className="inner-banner"
        backgroundColor={backgroundColor}
        height={height}
        top={top}
    >
      <PreloadImage style={{
        position: 'absolute',
        width: '100%',
        height:'100%',
        top:0,
        left:0,
        backgroundColor: '#DDD'
      }} src={image} />
      {image.length ? <img src={image} alt=""/> : ''}
      <Container>
        <Row>
          {/*<Col xs={12}>*/}
            <div className="inner-banner__content">
              <MiniBar
                fontWeight={100}
                fontSize={5}
              />
              <Title
                lineHeight={'60'}
                text={title}
                color={'#FFF'}
                fontSize={titleFontSize||54}
                font={'54'}
                margin={'20px 0 0px 0'}
              />
              <Text color={'#FFF'}><p>{text}</p></Text>

              {isButton ? (
                <Button
                  link={link || '/'}
                  margin={'40px 0 0 0'}
                  text={isButton}
                  color={'#fff'}
                  img={arrowImg}
                />
              ) : ''}
            </div>

            {/*<div className="inner-banner__goDown">*/}
            {/*    <img src={goDown} alt=""/>*/}
            {/*</div>*/}

          {/*</Col>*/}
        </Row>
      </Container>
    </StyledBanner>
  )
};

const StyledBanner = styled.div`
  height: ${props => props.height || 60}vh;
  position: relative;
  margin: 20px;
  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${props => props.backgroundColor || `rgba(0,0,0,0.4)`};
  }

  .container{
    height: 100%;
    position: relative;
    .row{
      height: 100%;
    }
    .col-md-12{
      height: 100%;
    }
    p{
      width: 80%;
    }
  }
  >img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
  }
  .inner-banner__content{
    position: absolute;
    bottom: 82px;
    z-index: 2;
    width: 50%;
    top: ${props => `${props.top}px` || null}
  }
  .inner-banner__goDown{
    position: absolute;
    right: 0;
    bottom: 150px;
    z-index: 2;
    animation: bouncing .800s ease-in-out infinite alternate;
  }
  @media(max-width:767px){
    margin: 8px;
    .inner-banner__goDown{
      display: none;
    }
    .inner-banner__content{
      bottom:78px;
      left: 15px;
      width: calc(100% - 30px);
      p{
        width: 100%;
      }
    }
    .sub-title{
      margin-bottom: 30px;
      font-size: 16px;
    }
    .title{
      font-size: 34px;
      line-height: 40px;
      margin-bottom: 20px;
    }
  }
  @keyframes bouncing{
    0%{
      transform: translateY(0px);
    }
    100%{
      transform: translateY(15px);
    }
  }
`;

export default InnerBanner;
