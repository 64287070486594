import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import Tab from './components/TabPanel';
import {connect} from 'react-redux';
import {fetchDivisionPageData} from '../../redux/actions/division'
import BannerImage from '../../assets/images/dynamic/inner-banner.jpg'
import {ApiParams} from "../../constants/apiParams";
import Menu from "../../components/Menu";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {baseUrl} from "../../components/BaseUrl";
import {Loading} from "../../components/Loading";
import {Helmet} from "react-helmet";


class Division extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        let params = {
            [ApiParams.page_type]: 'home-business-division'
        };

        this.props.getDivisionPageData(params)

        if(this.props.sectionId) {
            let elem = document.getElementById(this.props.sectionId)
            if (elem) elem.scrollIntoView({behavior: "smooth", block: "start"})
        }
    }


    render() {
        let {divPageData, footerOffset} = this.props

        // console.log('divPageLoading', this.props.divPageLoading)

        let bannerImage = divPageData ?
          divPageData.images ?
            divPageData.images.list ?
              divPageData.images.list[0] ?
                baseUrl + divPageData.images.list[0].path + divPageData.images.list[0].img_name : ''
              : ''
            : ''
          : ''

        let page_id = divPageData ? divPageData.page_data ? divPageData.page_data.id : null : null
        return (
            <>
                <Helmet>
                    <title>Concern | Mir Group</title>
                </Helmet>
                <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                    <Menu/>
                    {this.props.divPageLoading && <Loading/>}
                    <InnerBanner
                        title={'Concern'}
                        image={bannerImage}
                    />
                    {page_id ? (
                        <Tab page_id={page_id}/>
                    ) : null}
                </motion.div>
            </>
        )
    }
};

function mapStateToProps(state) {
    return {
        divPageData: state.divisionReducer.divPageData,
        divPageLoading: state.divisionReducer.divChildPageLoading,
        footerOffset: state.footerReducer.footerOffset,
        sectionId: state.menuReducer.sectionId
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getDivisionPageData: params => dispatch(fetchDivisionPageData(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Division);
