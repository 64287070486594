import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {hover} from "../../../globalStyleVars";
import Swiper from 'react-id-swiper';
import SubTitle from '../../../components/SubTitle';
import Links from '../../../components/Link';
import Button from '../../../components/Button';
import Title from '../../../components/Title';

// img
import RightIcon from "../../../assets/images/navRight.svg";
import LeftIcon from "../../../assets/images/navLeft.svg";
import aw1 from "../../../assets/images/dynamic/blur.jpg";
import bg from "../../../assets/images/achievements.jpg";
import {baseUrl} from "../../../components/BaseUrl";

const Achievements = ({ posts }) => {
  let directorsPost = posts ? posts.find(post => post.data.slug === 'top-management') : null
  let directorsImage = directorsPost ? directorsPost.images ? directorsPost.images : [] : []

  let len = directorsImage.length

  const params = {
    spaceBetween: 20,
    slidesPerView: 1,
    speed: 1500,
    // centeredSlides: true,
    // roundLengths: true,
    // loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    // autoplay: {
    //     delay: 5500,
    //     disableOnInteraction: true,
    // },
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 30,
        centeredSlides: false,
        loop: false
      },
      769: {
        slidesPerView: 4,
        spaceBetween: 30,
        centeredSlides: false,
        loop: false
      }
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
  };

  return (
    <StyledAchievements className="pt-110 pb-110">
      <Container className="title-here">
        <Title
          margin={'0 0 40px 0'}
          text={'Top Management'}
          color={'#304550'}
          fontSize={40}
        />
      </Container>

      <Container className="achievement-slider">
        {len && len === directorsImage.length ? (
          <Swiper  {...params}>
            {directorsImage.map(image => {
              let imagePath = baseUrl + image.path + image.img_name
              return (
                <div key={image.id} className="achievement-slider__single">
                  <div className="achievement-slider__single__img">
                    <img src={imagePath} alt=""/>
                  </div>
                  <h4>{image.short_title}</h4>
                  <p>{image.short_desc}</p>
                </div>
              )
            })}
          </Swiper>
        ) : ''}
      </Container>
    </StyledAchievements>

  )
};


const StyledAchievements = styled.div`
  
   background-position: center;
   background-repeat: no-repeat;
   -webkit-background-size: cover;
   background-size: cover;
   .title-here{
      .title{
        width: 60%;
       }
   }
   
   .achievement-slider{
       &__single{
           h4{
           margin-top: 30px;
            font-size: 20px;
            font-weight: bold;
            line-height: 32px;
            color: #438D44;
            margin-bottom: 2px;
          }
          p{
            font-size: 14px;
            font-weight: 300;
            line-height: 21px;
            color: #304550;
            margin-bottom: 0;
          }
        &__img{
            padding-top: 111.11%;
            position: relative;
            overflow: hidden;
            a{
              position: absolute;
              height: 100%;
              width: 100%;
              left: 0;
              top: 0;
              bottom: 0;
              z-index: 3;
            }
            img{
              position: absolute;
              height: 100%;
              width: 100%;
              object-fit: cover;
              top: 0;
              left: 0;
            }
         }
       }
      
    &__bottom{
     margin-top: -50px;
    }  
   }
   
  .swiper-button-next{
       position: absolute;
        right: 0px;
        bottom: 0px;
        margin: auto;
        z-index: 3;
        height: 50px;
        width: 50px;
        background-image: url(${RightIcon});
        background-position: center;
        background-size: contain;
        top: auto;
        left: auto;
         &:focus{
          outline:none;
        }
    }
    .swiper-button-prev{
       position: absolute;
        right: 60px;
        bottom: 0px;
        margin: auto;
        z-index: 3;
        height: 50px;
        width: 50px;
        top: auto;
        left: auto;
        background-image: url(${LeftIcon});
        background-size: contain;
        &:focus{
          outline:none;
        }
    }
   .swiper-wrapper{
       margin-bottom: 98px;
   }
   
   @media(min-width:768px){
    .swiper-pagination-bullet{
      display: none;
    }
   }
   
   @media(max-width:767px){
    .swiper-wrapper .sub-title{
      margin-bottom: 10px;
    }
    .title-here .sub-title{
       margin-bottom: 60px;
    }
    .title{
      font-size: 20px;
      line-height: 23px;
      height: 70px !important;
    }
    .swiper-wrapper {
        margin-bottom: 50px;
    }
    .swiper-button-next,.swiper-button-prev{
      display: none;
    }
   }
   
    .swiper-pagination{
    bottom: 0px;
    .swiper-pagination-bullet{
        height: 5px;
        width: 5px;
        background-color: #FFF;
        position: relative;
        margin-right: 20px;
        opacity: 1;
      &:after{
        content: '';
        height: 17px;
        width: 17px;
        border-radius: 50%;
        background-color: rgba(255,255,255,0.5);
        position: absolute;
        left: -6px;
        top: -6px;
        opacity: 0;
        z-index: -1;
      }
      &.swiper-pagination-bullet-active{
        &:after{
          opacity: 1;
        }
      }
    }
  }
`;


export default Achievements;
