import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import ImageBox from "../../../components/ImageBox";
import Lines from '../../../components/VerticalLine';
import {Link} from "react-router-dom";

import ReactHtmlParser from "react-html-parser";

import {ApiParams} from "../../../constants/apiParams";
import {connect} from 'react-redux';

/*Images*/

import ConcernThumbBg from "../../../assets/images/concern-thumb-bg.jpg"

import ConcernThumbLogo from "../../../assets/images/concern-thumb-logo.svg"
import {fetchDivisionPageData} from "../../../redux/actions/division";
import ConcernList from "./ConcernList";


class OurConcern extends Component {
  componentDidMount() {
    let params = {
      [ApiParams.page_type]: 'home-business-division'
    };
    this.props.getDivisionPageData(params)
  }

  render() {
    let {divPageData} = this.props;

    let divDetails = divPageData ? divPageData.page_data : null

    return (
      <StyledOurConcern className="pt-110 pb-110" id="godown">
        <Container>
          <Row>
            <Col xs={12}>
              <div className={'pad-lr-150 pad-b-60'}>
                <SubTitle
                  margin={'0 0 30px 0'}
                  textAlign={'center'}
                  text={'Concerns'}
                  fontSize={14}
                />
                <Title
                  fontSize={'36'}
                  margin={'0 0 30px 0'}
                  textAlign={'center'}
                  text={divDetails ? ReactHtmlParser(divDetails.sub_title) : ''}
                />
                <Text
                  fontSize={16}
                  margin={'0 0 30px 0'}
                  textAlign={'center'}
                >
                  <p>
                    {divDetails ? ReactHtmlParser(divDetails.description) : ''}
                  </p>
                </Text>
              </div>
            </Col>
            {divDetails ? <ConcernList page_id={divDetails.id} /> : null}
          </Row>
        </Container>
      </StyledOurConcern>
    )
  }
}


const StyledOurConcern = styled.div`
  //background-color: rgba(30,195,170,0.05);
  position: relative;
  .container{
    position: relative;
  }

  .no-padding{
    padding:0;
  }
  .no-margin{
    margin:0;
  }

  .pad-lr-150{
    padding:0 150px;
  }
  .pad-b-60{
    padding-bottom:60px;
  }

  .about__left{
    @media(max-width:767px){
      min-width: 100%;
      .title{
        margin-bottom: 17px !important;
      }
    }
  }
  .about__right{
    &__single{
      margin-bottom: 8px;
      h4{
        font-size: 110px;
        line-height: 147px;
        font-weight: 300;
        margin: 0;
      }
      p{
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
        margin: 0;
        text-transform: uppercase;
        span{
          font-weight: bold;
        }
      }
      &:nth-of-type(2){
        text-align: right;
      }
    }
    @media(max-width:767px){
      min-width: 100%;
      margin-top: 60px;
      margin-left: 0;
      .about__right__single{
        text-align: left;
        margin-bottom: 40px;
        &:nth-last-of-type(1){
          margin-bottom: 0;
        }
        h4{
          font-size: 50px;
          line-height: 80px;
        }
        p{
          font-weight: 400;
          br{
            display: none;
          }
        }
      }

    }
  }


  @media(max-width:767px){
    .pad-lr-150{
      padding:0;
    }
    .pad-b-60{
      padding-bottom:30px;
    }

    .mb-15{
      margin-bottom : 15px;
    }

  }
`;

function mapStateToProps(state) {
  return {
    divPageData: state.divisionReducer.divPageData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getDivisionPageData: params => dispatch(fetchDivisionPageData(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OurConcern);




