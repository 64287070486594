import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import {baseUrl} from '../../../components/BaseUrl';
import {connect} from "react-redux";
import {photos} from "./photos";
import Gallery from "react-photo-gallery";
import Lightbox from 'react-images';
// css

// img

import RightIcon from "../../../assets/images/navRight.svg";
import LeftIcon from "../../../assets/images/navLeft.svg";
import $ from "jquery";


class ImageGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImage: 0,
      lightboxIsOpen: false
    };

    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }

  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }

  componentDidMount() {
    let getOffset = $('.container').offset().left;

    setTimeout(function () {
      // if($('.business__single').length < 10){
      $('.division-gallery .swiper-pagination').prepend('<span class="total-0">0</span><span class="current-0">0</span>');
      // }
      if($(window).width() > 769){
        $('.division-gallery .swiper-pagination').css('right',getOffset+15);
        $('.division-gallery .swiper-button-next').css('right',getOffset+15);
        $('.division-gallery .swiper-button-prev').css('right',getOffset+15);
      }
    },500);
  }

  render() {
    let { posts,footerOffset } = this.props;

    let galleryData = posts ? posts.find(post => post.data.slug === 'images') : null

    let galleryImages = galleryData ? galleryData.images : [];

    let photoGallery = galleryImages ? galleryImages.map((image, key) => {
      let imgObj = {
        src: baseUrl + image.path + image.img_name,
        width: 3,
        height: key % 2 ? 4 : 2
      }

      return imgObj
    }) : []

    return (
      <StyledGallery className='division-gallery' offset={footerOffset}>
        <Container>
          <Title
            text={'gallery'}
            fontSize={42}
            color={'#304550'}
            textAlign={'center'}
            margin={'0 0 40px 0'}
          />
          {photoGallery.length ? (
            <>
              <Gallery photos={photoGallery} onClick={this.openLightbox} />
              {this.state.lightboxIsOpen ? (
                <Lightbox
                  images={photoGallery}
                  onClose={this.closeLightbox}
                  onClickPrev={this.gotoPrevious}
                  onClickNext={this.gotoNext}
                  currentImage={this.state.currentImage}
                  isOpen={this.state.lightboxIsOpen}
                />
              ) : null}
            </>
          ) : ''}
        </Container>
      </StyledGallery>
    )
  }
};

const StyledGallery = styled.div`
    height: auto;
    background-color: #EFFDF8;
    padding: 100px 200px;
  
  @media(max-width:768px){
    .swiper-pagination{
      top: 126px;
      right: 50px;
      
    }
    .swiper-button-next{
      right: 50px !important;
    }
    .swiper-button-prev{
      right: 50px !important;
    }
  }
  
  @media(max-width:767px){
    padding: 25px;
   .gallery__single{
      height: 400px;
     }
     .gallery-title{
      top: 50px !important;
     }
    .swiper-pagination{
      top: 50px;
      right: 15px;
      left: auto;
      span{
        font-size: 30px;
        font-weight: 300;
        line-height: 28px;
        &:nth-last-of-type(1){
          margin-top: -9px;
        }
      }
    }
    .swiper-button-next{
      right: 15px !important;
      bottom: 20px;
      top: auto;
      
    }
    .swiper-button-prev{
      right: 15px !important;
      bottom: 80px;
      top: auto;
      left:auto ;
    }
    .gallery__single__content{
      margin-top: 100px;
    }
    .col-sm-6{
      min-width: 100% !important;
    }
    .gallery__single__content .link{
      margin-top: 100px;
    }
     .business__single__content .link{
      margin-top: 100px;
    }
    .current-0,.total-0{
      right: 20px;
    }
  }
  
`;

let mapStateToProps = state => {
  return {
    footerOffset: state.footerReducer.footerOffset
  }
};
export default connect(mapStateToProps)(ImageGallery);
