import React, {Component} from 'react';
import Banner from './components/Banner';
import Form from './components/form';
import Menu from '../../components/Menu';
import {fetchFormData} from "../../redux/actions/form";
import {connect} from 'react-redux'
import {ApiParams} from "../../constants/apiParams";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import Map from './components/ContactMap';
import {Helmet} from "react-helmet";


class Career extends Component{
    componentDidMount() {
      window.scrollTo(0, 0);
      let param = {
        [ApiParams.form_slug]: 'contact-form'
      };
      this.props.getFormData(param)
    }

    render() {
      return(
        <>
            <Helmet>
                <title>Contact | Mir Group</title>
            </Helmet>
            <motion.div variants={PageAnimation} animate="show" exit="exit" initial="hidden">

                <Menu />
                <Banner />
                <Form />
            </motion.div>
        </>
      )
    }
};



let mapDispatchToProps = dispatch => {
  return {
    getFormData: params => dispatch(fetchFormData(params))
  }
}

export default connect(null, mapDispatchToProps)(Career);
