import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import {Container, Row, Col} from 'react-bootstrap';
import {baseUrl} from "../../../components/BaseUrl";
import ReactHtmlParser from "react-html-parser";
import Lines from '../../../components/VerticalLine';

// img
import MissionImg from '../../../assets/images/dynamic/mission.jpg';
import goDown from '../../../assets/images/goDown.svg';
import $ from "jquery";

const MissionVision = ({ posts }) => {
  let missionData = posts ? posts.find(post => post.data.slug === 'mission') : null
  let missionTitle = missionData ? missionData.data ? missionData.data.title : null : null
  let missionDescription = missionData ? missionData.data ? missionData.data.description : null : null

  let visionData = posts ? posts.find(post => post.data.slug === 'vision') : null
  let visionTitle = visionData ? visionData.data ? visionData.data.title : null : null
  let visionDescription = visionData ? visionData.data ? visionData.data.description : null : null



  useEffect(() => {
    let getOffset = $('.container').offset().left + 30;
    if ($(window).width() > 769) {
      $('.services-single:nth-of-type(even) .services-single__right').css({'padding-left': getOffset})
      $('.services-single:nth-of-type(odd) .services-single__right').css({'padding-right': getOffset})
    }
  },[])

  return (
    <StyledServices>
      <Container fluid className='p-0'>
        <Row className="services-single">
          <Col md={6}>
            <div className="services-single__left">
              <SubTitle
                text={'Mission'}
                color={'#fff'}
                fontWeight={400}
                fontSize={42}
                margin={'0 0 30px 0'}
              />
              <Text color={'#fff'}>
                <p>{ReactHtmlParser(missionDescription)}</p>
              </Text>
            </div>
          </Col>

          <Col md={6}>
            <div className="services-single__right">
              <SubTitle
                text={'Vision'}
                color={'#fff'}
                fontWeight={400}
                fontSize={42}
                margin={'0 0 30px 0'}
              />
              <Text color={'#fff'}>
                <p>{ReactHtmlParser(visionDescription)}</p>
              </Text>
            </div>
          </Col>
        </Row>
      </Container>
    </StyledServices>
  )
};

const StyledServices = styled.div`
  .services-single{
    &:nth-of-type(even){
      flex-direction: row-reverse;
    }
    .col-md-6{
      padding: 0;
    }
    .services-single__left{
      padding: 100px;
      position: relative;
      height: 100%;
      background: #304550;
    }
    .services-single__right{
      background: #395260;
      padding: 100px;
      position: relative;
      height: 100%;
    }
  }
  @media(max-width:991px){
    .services-single__right {
      padding-top: 10px !important;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .sub-title{
        margin-bottom: 20px;
      }
    }

    .services-single__left {
      padding-top: 10px !important;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .sub-title{
        margin-bottom: 20px;
      }
    }
    
    .col-md-6{
      .services-single__right{
        padding-left: 30px !important;
      }
      .services-single__left{
        padding-left: 30px !important;
      }
    }

  }
  @media(max-width:767px){
    .services-single{
      padding-bottom: 50px;
      .col-md-6 {
        padding:0 15px;
      }
      .col-md-6 {
        .services-single__right{
          padding-left: 15px !important;
          padding-right: 15px !important;

          .sub-title{
            margin-top:10px;
            margin-bottom: 10px;
          }
        }

        .services-single__left{
          padding-left: 15px !important;
          padding-right: 15px !important;

          .sub-title{
            margin-top:10px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
`;

export default MissionVision;
