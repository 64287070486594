import React, {useState} from 'react';
import {hover, darkGreen} from '../globalStyleVars';
import styled from 'styled-components';
import {Link, withRouter } from 'react-router-dom';
import arrowNewImg from '../assets/images/btn-arrow-new.png';
import arrowImg from '../assets/images/btn-arrow-white.svg';
import arrowImgHover from '../assets/images/btn-arrow-green.svg';
import VisibilitySensor from "react-visibility-sensor";

const Button = ({
                  history,
                  postProp,
                  noArrow,
                  onSubmit,
                  text,
                  link,
                  img,
                  hoverImg,
                  fontSize,
                  fontWeight,
                  color,
                  letterSpacing,
                  lineHeight,
                  margin,
                  background,
                  border,
                  width,
                  textAlign
                }) => {
  let [btnArrow, setBtnArrow] = useState(img)

  let handleArrowImgChange = (img) => {
    setBtnArrow(img)
  };

  let onRoute = () => {
    history.push({
      pathname: link,
      state: postProp
    })
  }

  return (
    <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
      {({isVisible}) =>
        <StyledBtn
          fontSize={fontSize}
          fontWeight={fontWeight}
          color={color}
          background={background}
          lineHeight={lineHeight}
          letterSpacing={letterSpacing}
          margin={margin}
          border={border}
          img={btnArrow}
          width={width}
          hoverImg={hoverImg}
          className={`${isVisible ? 'anim-active' : ''} fade-up dc-btn`}
        >
          {postProp ? (
            <a>
              <span
                onMouseOver={() => handleArrowImgChange(arrowImgHover)}
                onMouseLeave={() => handleArrowImgChange(img)}
                onClick={onRoute}
                style={textAlign ? { textAlign: textAlign } : {}}
              >
                {text}
              </span>
              {noArrow ?
                null : (
                  <span
                    className="outer-button"
                    onMouseOver={() => handleArrowImgChange(arrowImgHover)}
                    onMouseLeave={() => handleArrowImgChange(img)}
                    onClick={onRoute}
                  >
                    <img src={btnArrow} alt=""/>
                  </span>
                )
              }
            </a>
          ) : link ? (
            <a href={`https://www.google.com/maps/search/?api=1&query=${link.lat},${link.lng}`} target="_blank">
              <span
                onMouseOver={() => handleArrowImgChange(arrowImgHover)}
                onMouseLeave={() => handleArrowImgChange(img)}
                onClick={onSubmit}
                style={textAlign ? { textAlign: textAlign } : {}}
              >
                {text}
              </span>
              {noArrow ?
                null : (
                  <span
                    className="outer-button"
                    onMouseOver={() => handleArrowImgChange(arrowImgHover)}
                    onMouseLeave={() => handleArrowImgChange(img)}
                    onClick={onSubmit}
                  >
                    <img src={btnArrow} alt=""/>
                  </span>
                )
              }
            </a>
          ) : (
            <a>
              <span
                onMouseOver={() => handleArrowImgChange(arrowImgHover)}
                onMouseLeave={() => handleArrowImgChange(img)}
                onClick={onSubmit}
                style={textAlign ? { textAlign: textAlign } : {}}
              >
                {text}
              </span>
              {noArrow ?
                null : (
                  <span
                    onMouseOver={() => handleArrowImgChange(arrowImgHover)}
                    onMouseLeave={() => handleArrowImgChange(img)}
                    onClick={onSubmit}
                  >
                    <img src={btnArrow} alt=""/>
                  </span>
                )
              }
            </a>
          )}
        </StyledBtn>
      }
    </VisibilitySensor>
  )
};

const StyledBtn = styled.div`
  &.dc-btn{
    margin:${props => props.margin || '0'};
    width:${props => props.width || '173px'};
    height: 50px;
      a{
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        font-size: ${props => props.fontSize || 14}px;
        font-weight:${props => props.fontWeight || 500};
        margin:0;
        line-height:${props => props.lineHeight || 21}px;
        color:${props => props.color || `${hover}`};
        text-transform: capitalize;
        border:${props => props.border || `1px solid ${hover}`};
        border-right:0px;
        background-color:${props => props.background || `transparent`};
        letter-spacing:${props => props.letterSpacing || `2px`};    
        position: relative;
        cursor: pointer;
        // &:after{
        //   height: 0;
        //   background-color: ${hover};
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   content: '';
        //   position: absolute;
        //   transition:height .4s ease;
        // }
         span {
            height: 100%;
            width: 100%;
            z-index: 2;
            position: relative;
            transition: all .3s ease !important;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color:${props => props.color || `${hover}`};
            img{
              transition:transform .5s ease;
              margin-left: 10px;
              position: relative;
              object-fit: contain;
              display: inline;
              height: 14px;
            }
            &.outer-button{
                width:15px;
                justify-content:flex-end;
                position: relative;
                &:before{
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: 12px;
                    background-color: #1EC3AA;
                    top: 0;
                    right:0;
                }
                &:after{
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: 12px;
                    background-color: #1EC3AA;
                    bottom: 0;
                    right:0;
                }
                //img{
                //    transform:translate(8px);
                //}
            }
            
          
          }
          
          
          &:hover{
            border-color: ${hover} !important;
             span {
             color: ${hover};
              img{
                transform: translateX(10px);
              }
            }
            &:after {
              height:100%;
            }
          }
      }
  }    
`;


export default withRouter(Button);
