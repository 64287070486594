import React, {Component} from 'react';
import styled from 'styled-components';
import AboutImg from '../../../assets/images/dynamic/about-detail.jpg';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import {Col, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {ApiParams} from "../../../constants/apiParams";
import {fetchContactPageData} from '../../../redux/actions/contact/'
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import InnerBanner from "../../../components/InnerBanner";
import Map from './ContactMap';
import {baseUrl} from "../../../components/BaseUrl";
import MapButton from "../../../components/MapButton";
import arrowImg from "../../../assets/images/btn-arrow-green.svg";
import {Loading} from "../../../components/Loading";

class BannerDescription extends Component {

    componentDidMount() {
        let params = {
            [ApiParams.page_type]: 'contact'
        };
        this.props.getContactData(params);

        let getOffset = $('.container').offset().left +15;
        if ($(window).width() > 769) {
            setTimeout(function () {
                $('.description-content .description__wrap').css({'margin-left': getOffset, 'width': `calc(100% - ${getOffset}})`})
            })
        }
    }

    render() {

        let {address} = this.props;

        let bannerImage = address ?
          address.images ?
            address.images.list ?
              address.images.list[0] ?
                baseUrl + address.images.list[0].path + address.images.list[0].img_name : ''
              : ''
            : ''
          : ''

        let posts = address ? address.posts ? address.posts.list : [] : []

        let description = address ? address.page_data ? address.page_data.description : '' : ''

      let latData = posts ? posts.find(post => post.data.slug === 'lat') : null
      let lngData = posts ? posts.find(post => post.data.slug === 'lng') : null

      let pos = {
        'lat': latData ? parseFloat(latData.data.description) : null,
        'lng': lngData ? parseFloat(lngData.data.description) : null
      };

        return (
            <StyledBannerDescription offset={this.props.footerOffset}>
                {this.props.addressLoading &&  <Loading/>}
                <InnerBanner
                  title={'contact'}
                  image={bannerImage}
                />
                <Row>
                  <Col md={6} xs={12}>
                    <div className="description-content">
                      <div className="description__wrap">
                        <div className="contact-address">
                          <Row>
                            <Col md={12}>
                              <div className="contact-address__single">
                                <p>{ReactHtmlParser(description)}</p>
                              </div>
                              {latData ? (
                                <MapButton
                                  link={pos}
                                  margin={'40px 0 0 0'}
                                  text={'Google Maps'}
                                  img={arrowImg}
                                />
                              ) : ''}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} xs={12}>
                    <Map posts={posts} />
                  </Col>
                </Row>
            </StyledBannerDescription>
        )
    }

};

const StyledBannerDescription = styled.div`
  background-color: #F9F9F9;
  .description-content{
    .description__img{
      height: 600px;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        top: 0;
        background-color: rgba(0,0,0,0.6);
      }
      img{
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
       }
    }
  }
  .description__wrap{
    background-color: #F9F9F9;
     width: calc(100% - ${props => props.offset + 15});
     margin-left: ${props => props.offset + 15 || 200}px;
     padding: 100px;
     position: relative;
     z-index: 2;
    &__about{
      width: 60%;
    }
    .contact-address{
      &__single{
        h4{
          font-size: 18px;
          font-weight: 500;
          margin: 0 0 20px 0;
        }
        p{
          font-size: 16px;
          font-weight: 400;
        }
      }
        
    }
  }
  
  @media(max-width:1080px){
    .container{
      overflow-x: hidden;
    }
    overflow-x: hidden;
  }
  @media(max-width:1080px){
       .description__wrap{
       overflow: hidden;
        width: calc(100% - 15px);
        margin-left: 15px;
      }
  }
 
  @media(max-width:992px){
      .description__wrap__about{
        width: 100%;
       }
      .description__wrap{
        padding: 80px 35px;
      }
       .description__img {
            height: 500px;
            position: relative;
        }
  }
 
  
  @media(max-width:767px){
      .description__img {
        height: 500px;
        position: relative;
      }
      p{
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
      }
      .description__wrap__at-a-glance{
        margin-top: 60px;
        padding-top: 60px;
        table tr td{
         padding-top: 15px;
         padding-bottom: 15px;
         font-size: 16px !important;
         line-height: 21px !important;
        }
      }
      
  }
`;


let mapStateToProps = state => {
    return {
        footerOffset: state.footerReducer.footerOffset,
        address: state.contactReducer.address,
        addressLoading: state.contactReducer.addressMainLoading
    }
};

function mapDispatchToProps(dispatch) {
    return {
        getContactData: (params) => dispatch(fetchContactPageData(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerDescription);
