import React, {useEffect, Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import Select from 'react-select';
import News from "./News";
import {fetchMediaListData} from "../../../redux/actions/media";
import {ApiParams} from "../../../constants/apiParams";
import {connect} from 'react-redux'
import Title from "../../../components/Title";


const options = [
  {value: 'News', label: 'News'},
  {value: 'Events', label: 'Events'},
  {value: 'Photo', label: 'Photo'},
  {value: 'Video', label: 'Video'},
  {value: 'CSR', label: 'CSR'}
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
    backgroundColor: state.isSelected ? '#1EC3AA' : '#FFF',
    margin: 0
  }),
  menu: (provided, state) => ({
    ...provided,
    color: 'rgba(0,0,0,0.5)',
    backgroundColor: state.isSelected ? '#000' : '#FFF',
    margin: 0
  }),
  menuList: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#000' : '#FFF',
    borderRadius: 0,
  }),

};


class Jobs extends Component {
  state = {
    activePage: 0
  }

  handleOnClick = (id) => {
    this.setState({ activePage: id })
  }

  componentDidMount() {
    let param = {
      [ApiParams.page_id]: this.props.page_id
    }
    this.props.getMediaListData(param)
  }
  render() {
    let {mediaPageList} = this.props
    let mediaPageData = mediaPageList ? mediaPageList.map(media => media.page_data) : []

    mediaPageData = mediaPageData.length ? mediaPageData.filter(item => item.template === 'news') : []
    // console.log(mediaPageData, 'list');

    let mobileOption = mediaPageData ? mediaPageData.map((media, key) => {
      let data = {
        value: key,
        label: media.title
      }
      return data
    }) : []

    return (
      <StyledFilter>
        {mediaPageData.length ? mediaPageData.map(item => (
          <>
            <Container>
              <Title
                text={item.title}
                color={'#304550'}
                fontSize={36}
                lineHeight={46}
                margin={'20px 0 0 0'}
                textTransform={'capitalize'}
              />
            </Container>
            <News slug={item.slug} />
          </>
        )) : ''}
      </StyledFilter>
    )
  }
};


// filter
const StyledFilter = styled.div`
  padding:60px 0;
  min-height: 50vh;
  .job__menu{
    display: flex;
    //justify-content: space-between;
    li{
      font-size: 24px;
      font-weight: 100;
      padding: 10px 15px;
      line-height: 30px;
      color: rgba(0,0,0,0.15);
      display: block;
      text-transform: capitalize;
      transition:color .3s ease;
      cursor: pointer;
      &:hover{
        color: #000;
      }
      a{
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
        color: rgba(0,0,0,0.15);
        display: block;
        text-transform: capitalize;
        transition:color .3s ease;
        margin-right: 75px;
        &:hover{
          color: #000;
        }
      }
      &.active {
        color: #fff;
        background: #438D46;
      }
    }
  }



  @media(max-width:767px){
    .job__menu{
      display: none;
    }
    .sub-title{
      margin-bottom: 10px;
    }
  }

  .css-g1d714-ValueContainer{
    padding-left: 0;
  }
  .css-yk16xz-control,.css-1pahdxg-control{
    padding-left: 0;
    border:none;
    border-bottom:1px solid #D9D9D9 !important;
    background-color: transparent !important;
  }
  .css-tlfecz-indicatorContainer{
    padding-right: 0 !important;
    margin-right: 0 !important;
  }
  .css-1uccc91-singleValue{
    font-size: 20px;
    font-weight: bold;
  }

  @media(min-width:768px){
    .mobile-filter{
      display: none;
    }
  }


`;

function mapStateToProps(state) {
  return {
    mediaPageList: state.mediaReducer.mediaPageList
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getMediaListData: params => dispatch(fetchMediaListData(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
