import React, {useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Table} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import AboutImg from '../../../assets/images/dynamic/about-detail.jpg';
import ReactHtmlParser from "react-html-parser";
import {connect} from 'react-redux';
import { Element } from 'react-scroll';
import arrowImg from "../../../assets/images/btn-arrow-grey.svg";
import Modal from "../../../components/Modal";

const AboutUs = ({ description }) => {

  let [openModal, setOpenModal] = useState(false)

  let handleModal = () => {
    setOpenModal(!openModal)
  }

  return (
    <StyledAbout  id="who-we-are" className="pt-110">
      <Container>
        <Modal
          show={openModal}
          handleClose={handleModal}
          post={description}
          title={'Who We are'}
        />

        <Row>
          <Col md={12}>
            <Title
              color={'#58585A'}
              fontSize={'48'}
              fontWeight={'500'}
              margin={'0 0 60px 0'}
              lineHeight={'58'}
              text={'who we are'}
            />
            <Text
              margin={'0 0 30px 0'}
              color={'#58585A'}
              fontSize={16}
              line={8}
            >
              {description ? ReactHtmlParser(description) : ''}
            </Text>
            <div onClick={() => handleModal()}>
              <Button
                link={'/about'}
                margin={'40px 0 0 0'}
                text={'Read More'}
                color={'#304550'}
                img={arrowImg}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </StyledAbout>

  )
};

const StyledAbout = styled.div`
  padding-left: 100px;
  padding-right: 100px;
  @media(max-width:991px){
    &.pb-110{
      padding-bottom: 60px;
    }
    .col-md-7{
      min-width:100%;
    }
    .title{
      margin-bottom: 30px;
    }
  }
  @media(max-width: 767px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

export default AboutUs;
