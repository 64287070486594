import React, {useLayoutEffect, useEffect} from 'react';
import About from './components/CsrAbout';
import Gallery from './components/Gallery';
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {fetchDivisionListChildPage} from '../../redux/actions/division'
import {fetchFormData, postFormSubmit} from "../../redux/actions/form";
import Menu from '../../components/Menu';
import logoBlack from '../../assets/images/logo-black.svg';
import hamburderBlack from '../../assets/images/hamburger-black.svg';
import {ApiParams} from "../../constants/apiParams";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {fetchServicesPageData} from "../../redux/actions/services";
import {Helmet} from "react-helmet";


const CsrDetail = (props) => {
    let {slug} = props.match.params;

    useEffect(() => {
        let params = {
            [ApiParams.page_type]: 'csr'
        }
        props.getCsrPageData(params)

    }, [slug]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let {csrPage} = props

    let description = csrPage ? csrPage.page_data ? csrPage.page_data.description : '' : ''

    let posts = csrPage ?
        csrPage.posts ?
            csrPage.posts.count ? csrPage.posts.list : []
            : [] : [];

    return (
        <>
            <Helmet>
                <title>CSR | Mir Group</title>
            </Helmet>
            <div>
                <Menu/>
                {csrPage ? (
                    <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                        <About
                            slug={'CSR'}
                            posts={posts}
                            description={description}
                            csrPage={csrPage}
                        />
                        <Gallery posts={posts}/>
                    </motion.div>
                ) : ''}
            </div>
        </>
    )
};

function mapStateToProps(state) {
    return {
        csrPage: state.serviceReducer.servicePage,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCsrPageData: params => dispatch(fetchServicesPageData(params)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CsrDetail));
