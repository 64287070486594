import React, {useEffect, Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import Select from 'react-select';
import Photo from "./Photo";
import Video from "./Video";
import {fetchMediaListData} from "../../../redux/actions/media";
import {ApiParams} from "../../../constants/apiParams";
import {connect} from 'react-redux'


const options = [
  {value: 'News', label: 'News'},
  {value: 'Events', label: 'Events'},
  {value: 'Photo', label: 'Photo'},
  {value: 'Video', label: 'Video'},
  {value: 'CSR', label: 'CSR'}
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
    backgroundColor: state.isSelected ? '#1EC3AA' : '#FFF',
    margin: 0
  }),
  menu: (provided, state) => ({
    ...provided,
    color: 'rgba(0,0,0,0.5)',
    backgroundColor: state.isSelected ? '#000' : '#FFF',
    margin: 0
  }),
  menuList: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#000' : '#FFF',
    borderRadius: 0,
  }),

};


class Jobs extends Component {
  state = {
    activePage: 0
  }

  handleOnClick = (id) => {
    this.setState({ activePage: id })
  }

  componentDidMount() {
    if(!this.props.mediaPageList.length) {
      let param = {
        [ApiParams.page_id]: this.props.page_id
      }
      this.props.getMediaListData(param)
    }
  }
  render() {
    let {mediaPageList} = this.props
    let mediaPageData = mediaPageList ? mediaPageList.map(media => media.page_data) : []

    mediaPageData = mediaPageData.length ? mediaPageData.filter(item => item.template !== 'news' ) : []

    let mobileOption = mediaPageData.length ? mediaPageData.map((media, key) => {
      let data = {
        value: key,
        label: media.title
      }
      return data
    }) : []

    return (
      <>
        <StyledFilter id="media">
          <Container>
            <Row>
              <Col xs={12}>
                <ul className="job__menu">
                  {mediaPageData.length ? mediaPageData.map((media, key) => {
                      return (
                        <li
                          className={key === this.state.activePage ? "active" : ""}
                          onClick={() => this.handleOnClick(key)}
                        >
                          {/*<a>{media.title}</a>*/}
                          {media.title}
                        </li>
                      )
                    }
                  ) : null}
                </ul>
              </Col>

              <Col sm={12} className='mobile-filter'>
                <Select
                  styles={customStyles}
                  className='select-here'
                  placeholder='Select Media'
                  options={mobileOption}
                  onChange={op => this.handleOnClick(op.value)}
                />
              </Col>

            </Row>
          </Container>
        </StyledFilter>
        {mediaPageData.length ?
          mediaPageData[this.state.activePage].template === 'images' ? <Photo slug={mediaPageData[this.state.activePage].slug} /> :
            mediaPageData[this.state.activePage].template === 'video' ? <Video slug={mediaPageData[this.state.activePage].slug} />
              : <div style={{ minHeight: '100vh'}} />
          : <div style={{ minHeight: '100vh'}} />
        }
        {/*<News />*/}
        {/*<Photo />*/}
        {/*<Video />*/}
      </>
    )
  }
};


// filter
const StyledFilter = styled.div`
  background-color: #F3FCFB;
  padding:60px 0;
  .container{
    overflow: visible !important;
  }
  .job__menu{
    display: flex;
    //justify-content: space-between;
    li{
      font-size: 24px;
      font-weight: 100;
      padding: 10px 15px;
      line-height: 30px;
      display: block;
      text-transform: capitalize;
      transition:color .3s ease;
      cursor: pointer;
      &:hover{
        color: #000;
      }
      a{
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
        color: rgba(0,0,0,0.15);
        display: block;
        text-transform: capitalize;
        transition:color .3s ease;
        margin-right: 75px;
        &:hover{
          color: #000;
        }
      }
      &.active {
        color: #fff;
        background: #438D46;
      }
    }
  }



  @media(max-width:767px){
    .job__menu{
      display: none;
    }
    .sub-title{
      margin-bottom: 10px;
    }
  }

  .css-g1d714-ValueContainer{
    padding-left: 0;
  }
  .css-yk16xz-control,.css-1pahdxg-control{
    padding-left: 0;
    border:none;
    border-bottom:1px solid #D9D9D9 !important;
    background-color: transparent !important;
  }
  .css-tlfecz-indicatorContainer{
    padding-right: 0 !important;
    margin-right: 0 !important;
  }
  .css-1uccc91-singleValue{
    font-size: 20px;
    font-weight: bold;
  }

  @media(min-width:768px){
    .mobile-filter{
      display: none;
    }
  }


`;

function mapStateToProps(state) {
  return {
    mediaPageList: state.mediaReducer.mediaPageList
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getMediaListData: params => dispatch(fetchMediaListData(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
