import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import SubTitle from '../../../components/SubTitle';
import Links from '../../../components/Link';
import Button from '../../../components/Button';
import Title from '../../../components/Title';
import {baseUrl} from '../../../components/BaseUrl';
import ReactHtmlParser from "react-html-parser";
import achievement from '../../../assets/images/achievements.jpg'
// css
import "swiper/swiper.scss";

// img
import arrowImg from '../../../assets/images/btn-arrow-black.svg'
import RightIcon from "../../../assets/images/navRightBlack.svg";
import LeftIcon from "../../../assets/images/navLeftBlack.svg";
import aw1 from "../../../assets/images/dynamic/aw1.jpg";
import aw2 from "../../../assets/images/dynamic/aw2.jpg";
import aw3 from "../../../assets/images/dynamic/aw3.jpg";

const Awards = ({ posts }) => {
  let awardImageData = posts ? posts.find(post => post.data.slug === 'board-of-directors') : null
  let awardImageList = awardImageData ? awardImageData.images ? awardImageData.images : [] : []

  let len = awardImageList.length

  const params = {
    spaceBetween: 20,
    slidesPerView: 1,
    speed: 1500,
    autoplay: {
      delay: 5500,
      disableOnInteraction: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 30,
        centeredSlides: false,
        loop: false
      },
      769: {
        slidesPerView: 3,
        spaceBetween: 30,
        centeredSlides: false,
        loop: false
      }
    },
  };

  return (
    <StyledNewsEvents className="pt-110">
      <Container id="award" className="title-here">
        <Title
          fontSize={42}
          lineHeight={58}
          fontWeight={400}
          textTransform={'initial'}
          margin={'0 0 60px 0'}
          color={"#474747"}
          text={'Board Of Directors'}
        />
      </Container>

      <Container className="awards-slider">
        {len && len === awardImageList.length ? (
        <Swiper  {...params}>
          {awardImageList.map(image => {
              let imagePath = baseUrl + image.path + image.img_name
              return (
                <div className="awards-slider__single">
                  <div className="awards-slider__single__img">
                    <img src={imagePath} alt=""/>
                  </div>
                  <div className="mobile-text">
                    <h4>{image.short_title}</h4>
                    <p>{image.short_desc}</p>
                  </div>
                  <div className="hover-text">
                    <h4>{image.short_title}</h4>
                    <p>{image.short_desc}</p>
                  </div>
                </div>
              )
          })}
        </Swiper>
        ) : null}
      </Container>
    </StyledNewsEvents>

  )
};


const StyledNewsEvents = styled.div`
  background-color: #F2F2F2;

  .title-here {
    .title {
      width: 60%;
    }
  }
  
  
  .mobile-text {
    display: none;
  }

  .hover-text {
    opacity: 0;
    visibility: hidden;
    transition: all .5s ease;
    position: absolute;
    top: 15px;
    left: 25px;
    background-color: rgba(0, 140, 68, 0.9);
    right: 15px;
    bottom: 15px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: alias;

    h4 {
      color: white;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      margin: 0 0 8px 0;
    }

    p {
      margin: 0;
      color: #E6E6E6;
      font-size: 14px;
      font-weight: 300;
      line-height: 21px;
    }
  }

  .awards-slider {
    .swiper-slide .title {
      height: 60px;
      overflow: hidden;
    }

    &__single {
      &__content {

        h2 {
          width: 80%;
        }

      }

      &__img {
        padding-top: 121.62%;
        position: relative;
        overflow: hidden;

        a {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          z-index: 3;
        }

        img {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          top: 0;
          left: 0;
        }
      }

      &:hover .hover-text {
        opacity: 1;
        visibility: visible;
      }
    }

    &__bottom {
      margin-top: -50px;
    }
  }

  .swiper-button-next {
    position: absolute;
    right: 0px;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    background-image: url(${RightIcon});
    background-position: center;
    background-size: contain;
    top: auto;
    left: auto;

    &:focus {
      outline: none;
    }
  }

  .swiper-button-prev {
    position: absolute;
    right: 60px;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    top: auto;
    left: auto;
    background-image: url(${LeftIcon});
    background-size: contain;

    &:focus {
      outline: none;
    }
  }

  .swiper-wrapper {
    margin-bottom: 75px;
  }

  @media (max-width: 991px) {
    .title-here .title {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    .swiper-slide .sub-title {
      margin-bottom: 10px;
    }

    .swiper-slide .title {
      font-size: 20px;
      line-height: 23px;
      height: 70px !important;
    }

    .swiper-wrapper {
      margin-bottom: 90px;
    }
    
    .mobile-text {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h4 {
        margin-top: 30px;
        color: #438D44;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        margin: 0 0 8px 0;
      }

      p {
        margin: 0;
        color: #304550;
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
      }
      
    }
    
  }
`;


export default Awards;

