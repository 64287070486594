import React from 'react'
import {Col, Row} from "react-bootstrap";
import ImageBox from "../../../components/ImageBox";
import ConcernThumbBg from "../../../assets/images/concern-thumb-bg.jpg";
import ConcernThumbLogo from "../../../assets/images/concern-thumb-logo.svg";
import {connect} from "react-redux";
import {fetchDivisionListChildData, fetchDivisionListData} from "../../../redux/actions/division";
import {ApiParams} from "../../../constants/apiParams";
import {baseUrl} from "../../../components/BaseUrl";

class ConcernList extends React.Component {
  componentDidMount() {
    let params = {
      [ApiParams.page_id]: this.props.page_id
    }

    this.props.getDivisionListData(params)
  }

  render() {
    let { divListData } = this.props;

    return (
      <Col xs={12}>
        <Row className={`no-margin`}>
          {divListData ? divListData.map(page => {
            let pageData = page.page_data
            let thumb = page.images ?
              page.images.list ?
                page.images.list[0] ? baseUrl + page.images.list[0].path + page.images.list[0].img_name : ''
              : ''
            : ''
            let logo = page.images ?
              page.images.list ?
                page.images.list
            : [] : []

            return (
              <Col xs={6} md={3} className={`no-padding mb-15`}>
                <ImageBox
                  bgSrc={thumb}
                  logoSrc={logo}
                  text={pageData.title}
                  link={`/concern-detail/${pageData.slug}`}
                ></ImageBox>
              </Col>
            )
          }) : null}
        </Row>
      </Col>
    )
  }
}

function mapStateToProps(state) {
  return {
    divListData: state.divisionReducer.divListData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getDivisionListData: params => dispatch(fetchDivisionListData(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConcernList)
