import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import Jobs from './components/Jobs';
import Menu from "../../components/Menu";
import {fetchCareerPageData} from "../../redux/actions/career";
import {connect} from 'react-redux'
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import {Loading} from "../../components/Loading";
import {Helmet} from "react-helmet";

class Career extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);

        let param = {
          [ApiParams.page_type]: 'career'
        }
        this.props.getCareerPageData(param)
    };
    render() {
      let { careerPage } = this.props
      let careerData = careerPage ? careerPage.page_data : null
      let careerPageSub = careerData ? careerData.title : ''
      let careerPageTitle = careerData ? careerData.sub_title : ''
      let careerPageText = careerData ? careerData.short_desc : ''

      let careerPageImg = careerPage ?
        careerPage.images ?
          careerPage.images.list ? careerPage.images.list[0] : null
          : null
        : null

      let careerBanner = careerPageImg ? baseUrl + careerPageImg.path + careerPageImg.img_name : ''

      return(
          <>
              <Helmet>
                  <title>Career | Mir Group</title>
              </Helmet>
              <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                  <Menu />
                  {this.props.careerPageLoading && <Loading/>}
                  <InnerBanner
                      title={'Career'}
                      image={careerBanner}
                  />
                  <Jobs careerPage={careerPage} />
              </motion.div>
          </>
      )
    }
};

function mapStateToProps(state) {
  return {
    careerPage: state.careerReducer.careerPage,
      careerPageLoading: state.careerReducer.careerPageLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getCareerPageData: params => dispatch(fetchCareerPageData(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Career);
