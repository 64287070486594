import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import Lines from '../../../components/VerticalLine';
import {Link} from "react-router-dom";
import InnerBanner from "../../../components/InnerBanner";
import TeamImage from "../../../assets/images/homeTeam.jpg";

import {ApiParams} from "../../../constants/apiParams";
import {connect} from 'react-redux';
import {fetchHomeAboutUsData} from "../../../redux/actions/home";
import arrowImg from "../../../assets/images/btn-arrow-new.svg";
import {baseUrl} from "../../../components/BaseUrl";
import $ from "jquery";

class HomeTeam extends Component {
  componentDidMount() {
    let getOffset = $('.container').offset().left;
    if($(window).width() > 769){
      $('.content-left p').css('padding-left',getOffset+15)
    }
  }

  render() {
    let {homeTeamData} = this.props

    let bannerImageData = homeTeamData ?
      homeTeamData.images ?
        homeTeamData.images.list ? homeTeamData.images.list[1] : null
      : null
      : null

    let bannerImage = bannerImageData ? baseUrl + bannerImageData.path + bannerImageData.img_name : ''
    let bannerTitle = bannerImageData ? bannerImageData.short_title : ''

    return (
      <>
      <StyledAbout className="pt-110 pb-110">
        <InnerBanner
          title={bannerTitle}
          image={bannerImage}
          titleFontSize={36}
          top={100}
          backgroundColor={'rgba(0,0,0,0.2)'}
          link={'/about'}
          height={70}
          isButton={'Meet Our Team'}
        />
      </StyledAbout>
        <StyledContact>
          <Link to={'/contact'}></Link>
          <div className="content-left">
            <p>Contact Us</p>
          </div>
          <div className="content-right">
            <img src={arrowImg} />
          </div>
        </StyledContact>
      </>
    )
  }
}


const StyledAbout = styled.div`

`;

const StyledContact = styled.div`
  height: 250px;
  display: flex;
  position: relative;
  
  a {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    left: 0;
    top: 0;
  }
  
  .content-left {
    position: relative;
    display: flex;
    align-items: center;
    width: calc(100% - 268px);
    background-color: #304550;
    
    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      right: 0;
      top: 0;
      background-color: #008C44;
      transition: width 0.5s ease;
    }
    
    p {
      font-size: 36px;
      line-height: 46px;
      color: white;
      margin-bottom: 0px;
      position: relative;
      z-index: 1;
    }
  }
  .content-right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 268px;
    background-color: #008C44;
    
    img {
      height: 40px;
    }
  }

  &:hover {
    .content-left:before {
      width: 0;
    }
  }
  
  @media (max-width: 767px) {
    height: 180px;
    .content-left {
      width: calc(100% - 100px);
      
      p {
        font-size: 18px;
        padding-left: 15px;
      }
    }
    .content-right {
      width: 100px;
      
      img {
        height: 25px;
      }
    }
  }
`;

function mapStateToProps(state) {
  return {
    homeTeamData: state.homeReducer.bannerData
  };
}

export default connect(mapStateToProps)(HomeTeam);
